.fishing-container {
	justify-content: center;
	column-gap: 6px;
}

.fishing-info {
	text-align: center;
	grid-row: span 2;
	max-height: 494px;
	position: relative;
	display: grid;
	grid-template-rows: auto 1fr;
	border-image-outset: 10px !important;
	padding: 20px !important;
}

.fishing-info > div {
	position: relative;
}

.fishing-info > p {
	padding: 0 20px 20px;
	max-width: 425px;
	max-height: 500px;
	overflow-y: auto;
	line-height: 20px;
	margin-top: 10px;
	font-size: 14px;
}

.fishing-info-title-border {
	width: 80%;
	border-bottom: 1px solid white;
	margin: 8px auto;
	padding-bottom:5px;
	border-radius: 10px;
}

.fishing-tacklebox {
	text-align: center;
	justify-self: left;
	height: 240px;
	width: 100%;
	border-image-outset: 10px !important;
	padding: 20px !important;
}

.fishing-tacklebox-items {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.fishing-info-content-wrapper {
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.fishing-info-content-wrapper > div {
	height: 100%;
}

.mobile-layout .fishing-info {
	grid-column: 1 / -1;
	grid-row: span 1;
	min-height: auto;
}

.mobile-layout .fishing-info-content-wrapper {
	max-height: 200px;
}

.mobile-layout .fishing-tacklebox {
	height: 210px;
}
