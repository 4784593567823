.play-area-container {
	position: relative;
	min-width: 225px;
	min-height: 100px;
	overflow: auto;
}

.mobile-inventory-button {
	display: none;
}

@media (max-width: 999px) {
	.play-area-container {
		min-width: 100%;
		width: 100%;
	}

	.mobile-inventory-button {
		display: block;
	}
}

.nav-tab-close-button {
	margin-top: 2px;
	margin-left: 5px;
	position: relative !important;
}

.nav-tab {
	display: flex;
	padding-right: 5px;
	height: 100%;
	padding-left: 5px;
	font-size: 20px;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.582), rgba(0, 0, 0, 0.329)),
		url(/images/ui/frame_panel_light.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 10px;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: transform 0.05s;
	position: relative;
}

.nav-tab-container {
	display: flex;
	height: 30px;
	align-items: center;
	overflow: hidden;
}

.nav-tab-flex {
	height: 100%;
	flex: 1;
	font-size: 20px;
	border-bottom: none;
}

.selected-tab {
	background-image: linear-gradient(to bottom, rgba(179, 202, 255, 0.202), rgba(255, 255, 255, 0.112)),
		url(/images/ui/frame_panel.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.selected-tab:hover {
	cursor: default;
}

.nav-tab:hover {
	transform: scale(1.05);
}

.nav-tab.selected-tab:hover {
	transform: none;
}

.nav-tab-icon {
	width: 24px;
	height: 24px;
	margin: auto 5px auto 2px;
}

.nav-tab-icon.icon-border {
	-webkit-filter: drop-shadow(1px 1px 0 #1b1f22) drop-shadow(-1px 1px 0 #1b1f22) drop-shadow(1px -1px 0 #1b1f22)
		drop-shadow(-1px -1px 0 #1b1f22);

	filter: drop-shadow(1px 1px 0 #1b1f22) drop-shadow(-1px 1px 0 #1b1f22) drop-shadow(1px -1px 0 #1b1f22)
		drop-shadow(-1px -1px 0 #1b1f22);
}

@media only screen and (max-width: 1500px) {
	.game-right-panel .nav-tab-container > .nav-tab {
		font-size: 18px;
		line-height: 26px;
		padding-right: 15px;
		padding-left: 7px;
	}

	.game-right-panel .nav-tab-container > .nav-tab > img {
		width: 18px;
		height: 18px;
	}
}

@media only screen and (max-width: 1300px) {
	.game-right-panel .nav-tab-container > .nav-tab {
		font-size: 15px;
		line-height: 26px;
		padding-right: 7px;
		padding-left: 0;
	}

	.game-right-panel .nav-tab-container > .nav-tab > img {
		width: 16px;
		height: 16px;
	}
}
