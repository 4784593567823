.monetization-purchase-popup {
	border-image-source: url('/images/ui/stone-9slice-platinum.png') !important;
}

.monetization-purchase-popup .MuiPaper-root {
	border-image-source: url('/images/ui/stone-9slice-platinum.png') !important;
	overflow-y: hidden;
}

.monetization-purchase-popup-title {
	font-size: 48px;
	text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(54 188 250 / 80%), 0 0 20px rgb(54 188 250 / 80%),
		0 0 25px rgb(54 188 250 / 80%), 0 0 30px rgb(54 188 250 / 80%), 0 0 35px #e60073;
	text-align: center;
}

.monetization-purchase-popup-image-container {
	position: relative;
}

.monetization-purchase-popup-image-container .sun {
}

.monetization-purchase-popup-image {
	padding: 30px;
	width: 150px;
	display: block;
	position: relative;
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	margin: 10px auto;
	z-index: 1;
}

.sun,
.sun2 {
	position: absolute;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background: white;
	opacity: 0.9;
	box-shadow: 0 0 40px 15px white;
}

.sun2 {
	transform: rotate(110deg);
}

.sun2 .ray {
	background: linear-gradient(rgba(0, 42, 255, 0) 0, rgba(5, 217, 241, 0.8) 50%, rgba(8, 77, 237, 0) 100%);
}

.sun2 .ray_box {
	animation: ray_anim 60s linear infinite;
}

.ray_box {
	position: absolute;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 70px;
	animation: ray_anim 120s linear infinite;
	scale: 0.9;
}
.ray {
	background: linear-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
	margin-left: 10px;
	border-radius: 80% 80% 0 0;
	position: absolute;
	opacity: 0.3;
}

.ray1 {
	height: 170px;
	width: 30px;
	transform: rotate(180deg);
	top: -175px;
	left: 15px;
}
.ray2 {
	height: 100px;
	width: 8px;
	transform: rotate(220deg);
	top: -90px;
	left: 75px;
}
.ray3 {
	height: 170px;
	width: 50px;
	transform: rotate(250deg);
	top: -80px;
	left: 100px;
}
.ray4 {
	height: 120px;
	width: 14px;
	transform: rotate(305deg);
	top: 30px;
	left: 100px;
}
.ray5 {
	height: 140px;
	width: 30px;
	transform: rotate(-15deg);
	top: 60px;
	left: 40px;
}
.ray6 {
	height: 90px;
	width: 50px;
	transform: rotate(30deg);
	top: 60px;
	left: -40px;
}
.ray7 {
	height: 180px;
	width: 10px;
	transform: rotate(70deg);
	top: -35px;
	left: -40px;
}
.ray8 {
	height: 120px;
	width: 30px;
	transform: rotate(100deg);
	top: -45px;
	left: -90px;
}
.ray9 {
	height: 80px;
	width: 10px;
	transform: rotate(120deg);
	top: -65px;
	left: -60px;
}
.ray10 {
	height: 190px;
	width: 23px;
	transform: rotate(150deg);
	top: -185px;
	left: -60px;
}

@keyframes ray_anim {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
