.idlescape-progress-bar-filler-animation-theme-smithing-progress {
	will-change: filter, clip-path;
	animation-timing-function: linear;
	animation-name: defaultProgress;
}

.idlescape-progress-bar-filler-animation-theme-augmenting {
	will-change: filter, clip-path;
	animation-timing-function: linear;
	animation-name: augmentingProgress;
}

@keyframes augmentingProgress {
	0% {
		clip-path: inset(0 97% 0 0);
	}
	100% {
		clip-path: inset(0 3% 0 0);
	}
}

.idlescape-progress-bar-filler-animation-theme-default {
	will-change: filter, clip-path;
	animation-timing-function: linear;
	animation-name: defaultProgress;
}

@keyframes defaultProgress {
	0% {
		clip-path: inset(0 100% 0 0);
	}
	100% {
		clip-path: inset(0 0 0 0);
	}
}

.idlescape-progress-bar-filler-animation-theme-cooking {
	will-change: filter, clip-path;
	animation-timing-function: linear;
	animation-name: cookingProgress;
}

@keyframes cookingProgress {
	0% {
		clip-path: inset(100% 0 0 0);
	}
	100% {
		clip-path: inset(0 0 0 0);
	}
}

.idlescape-progress-bar-filler-animation-theme-combat {
	will-change: filter, clip-path;
	animation-timing-function: linear;
	animation-name: combatProgress;
}

@keyframes combatProgress {
	0% {
		clip-path: inset(0 50% 0 50%);
	}
	100% {
		clip-path: inset(0 3% 0 3%);
	}
}

.idlescape-progress-bar-filler-animation-theme-scrapping {
	will-change: filter, clip-path;
	animation-timing-function: linear;
	animation-name: scrappingProgress;
}

@keyframes scrappingProgress {
	0% {
		clip-path: inset(0 0 85% 0);
	}
	100% {
		clip-path: inset(0 0 20% 0);
	}
}

.idlescape-progress-bar-filler-animation-theme-snowball {
	will-change: filter, clip-path;
	animation-timing-function: linear;
	animation-name: snowballProgress;
}

@keyframes snowballProgress {
	0% {
		clip-path: inset(0 0 0 0);
	}
	100% {
		clip-path: inset(0 100% 0 0);
	}
}
