.email-registered-body {
	color: white;
	height: 100%;
	width: 100%;
}

.email-registered-box {
	align-items: center;
	border-image-source: url(/images/ui/stone-9slice.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 100px !important;
	border-image-outset: 50px !important;
	border-image-repeat: round !important;
	text-align: center;
	width: 40%;
	margin-left: auto;
	margin-right: auto;
}

.email-registered-box > p {
	font-size: 20px;
}

.email-registered-body img {
	width: 40%;
}
