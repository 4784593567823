.idlescape-tooltip {
	border-image-source: url(/images/ui/stone-9slice.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 50px !important;
	border-image-outset: 0 !important;
	/* padding: 50px !important; */
	border-image-repeat: round !important;

	padding: 15px 20px;
	max-width: min(max(25vw, 400px), 100vw);

	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.idlescape-tooltip-row-2 {
	display: grid;
	grid-template-columns: auto auto;
}

.idlescape-tooltip-row-2 > div:nth-child(2) {
	justify-self: end;
}

.idlescape-tooltip-row-3 {
	display: grid;
	grid-template-columns: auto auto auto;
}

.idlescape-tooltip-space-top {
	margin-top: 5px;
}
