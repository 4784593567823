.combat-bar-container {
	position: absolute;
	height: 100%;
	width: 100%;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.combat-bar-container > div {
	z-index: 131;
}

/* combat screen bar box */
.combat-bar-content {
	max-height: calc(100% - 40px);
	max-width: 100%;
	margin: 0 auto;
}

.combat-bar-content > div {
	overflow-y: auto;
	max-height: 100%;
}

.combat-bar-container .combat-inventory-container {
	max-height: calc(100% - 40px);
	overflow-y: auto;
}

.combat-bar-button {
	text-align: center;
	height: 40px;
	cursor: pointer;
	background-image: url(/images/ui/frame_box.png);
	background-size: 100% 100%;
}

.combat-bar-button > img {
	object-fit: contain;
	height: 100%;
	padding: 5px;
}

.combat-bar-button.selected {
	background-image: url(/images/ui/frame_box_light.png);
}
