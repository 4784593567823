.characters-container {
	cursor: default;
	font: 14px 'Exo 2', sans-serif;
	background-image: url(/images/landing_page_blurred.png);
	background-size: cover;
	height: 100%;
	width: 100%;

	background-repeat: no-repeat;
	position: relative;
	overflow: auto;
}

.character-selection-characters {
	display: grid;
	grid-template-columns: repeat(auto-fill, 400px);
	width: 100%;
	row-gap: 20px;
	justify-content: center;
	column-gap: 20px;
}

.character-selection-header > img {
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.character-select-container,
.character-creation-container {
	background-color: darkgray;
	color: white;
	width: 100%;
	height: 100%;
	background-color: rgba(56, 56, 56, 0);
	/* box-shadow:0 5px 4px rgba(0, 0, 0, 0.3); */

	border-image-source: url(/images/ui/stone-9slice.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 80px !important;
	border-image-outset: 0 !important;
	padding: 30px !important;
	border-image-repeat: round !important;
	box-sizing: border-box;
	transition: 0.3s;
}

.character-select-container:hover {
	transform: scale(1.03);
	/* border-radius: 20px; */
	box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7), inset 0 0 20px 0 rgba(255, 255, 255, 0.7);
	border-radius: 10px;
}

.character-creation-title {
	font-size: 28px;
	text-align: center;
}

.character-creation-name-input-label {
	text-align: center;
	margin-bottom: 5px;
	font-size: 18px;
	margin-top: -5px;
}

.character-creation-name-input {
	border-radius: 20px !important;
	background-color: rgba(0, 0, 0, 0.3) !important;
	text-align: center;
	color: white !important;
	font-weight: bold;
}

.character-creation-league-select {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 20px;
}

.character-league-selected img {
	border-radius: 30px;
	background-color: rgba(44, 83, 100, 1);
	box-shadow: 0 0 5px 5px rgba(44, 83, 100, 1);
}

.character-creation-league-title {
	text-align: center;
	margin-top: -10px;
	margin-bottom: 10px;
}

.character-creation-league-select img {
	cursor: pointer;
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.character-creation-create-button {
	background-image: url(/images/ui/stone_button_green.png);
	height: 40px;
	width: 250px;
	align-items: center;
	justify-content: center;
	background-size: 100% 100%;
	margin-top: 15px;
	margin-bottom: 0 !important;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
	display: block;
	text-align: center;
	line-height: 40px;
}

.character-username-and-image {
	display: grid;
	grid-template-columns: 120px 3fr;
}

.character-image {
	position: relative;
	height: 120px;
}

.character-inner-image {
	position: relative;
	height: 120px;
	overflow: hidden;
}

.character-image-league {
	height: 50px;
	width: 50px;
	z-index: 2;
	bottom: -15px;
	left: calc(50% - 25px);
	position: absolute;
	filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
}

.character-image-background-border {
	background-color: rgba(17, 17, 17, 0.459);
	height: 104%;
	width: 104%;
	position: absolute;
	top: -2%;
	left: -2%;
	clip-path: circle(50% at 50% 50%);
}

.character-username-and-levels {
	padding-left: 20px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.character-username {
	font-size: 24px;
	text-align: center;
}

.character-levels {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.character-level > img {
	height: 50px;
	width: 50px;
	float: left;
}

.character-level > span {
	font-size: 20px;
	margin-left: 5px;
	line-height: 50px;
}

.extended-hr {
	margin-left: -40px;
}

.character-last-logged-in {
	margin-top: 20px;
	text-align: center;
}

.character-action {
	margin-top: 20px;
	text-align: center;
}

.character-container hr {
	border-color: gray;
}

.character-gold-and-heat {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
}

.character-gold-and-heat img {
	height: 50px;
	width: 50px;
	float: left;
}

.character-gold-and-heat span {
	font-size: 20px;
	margin-left: 5px;
	line-height: 50px;
}

.extended-decorative-line {
	margin-left: -40px;
	width: 250px !important;
}

.decorative-line {
	display: block;
	margin-top: 1rem;
	margin-bottom: 1rem;
	height: 10px;
	width: 100%;
	z-index: 2000000;
	background-image: url('/images/ui/decorative_line_v2.png');
	background-position: center;
	overflow: hidden;
	/* filter: brightness(1.5); */
}

.character-creation-error {
	text-align: center;
	margin: 0 !important;
	padding: 0 !important;
	color: red;
	display: block;
}

.character-creation-error-show {
	display: block;
}

#league-node-tooltip {
	width: 24px;
	height: 24px !important;
	background-color: rgba(128, 128, 128, 0);
	color: white;
	white-space: pre-wrap;
	z-index: 100;
}

.MuiTooltip-popper {
	font-size: 50px !important;
}

.league-tooltip {
	background-color: rgba(34, 34, 34, 0.753) !important;
	border: solid white 1px;
	color: white;
	font-size: 14px;
}

.league-tooltip-text {
	font-size: 14px;
}

.character-selection-header {
	display: grid;
	grid-template-columns: auto auto auto;
	width: 100%;
	text-align: center;
	padding-top: 20px;
	margin-left: auto;
	margin-right: auto;
}

.character-selection-header > div:nth-child(1) {
	justify-self: right;
	align-self: end;
}
.character-selection-header > div:nth-child(3) {
	justify-self: left;
	align-self: end;
}

.character-image .combat-avatar-mugshot-container {
	height: 100px;
	width: 100px;
	/* background-color: green; */
	right: 50px;
	clip-path: circle(60% at 90% 40%);
}

.character-image .combat-avatar-mugshot-container .combat-fight-image-container {
	clip-path: unset;
}

/* .character-image .combat-avatar-mugshot-container > div img {
	margin-right: 80px;
} */

.character-inactive {
	opacity: 0.8;
	filter: grayscale(100%);
	pointer-events: none;
}

.character-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, 450px);
	justify-content: center;
	gap: 10px;
	overflow: visible;
}

.character-grid-title {
	text-align: center;
	font-size: 32px;
	margin-bottom: 0px;
}
