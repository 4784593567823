.chat-container {
	display: grid;
	grid-template-columns: minmax(150px, 10%) 1fr;
	grid-template-rows: 1fr 30px;
	row-gap: 5px;
	column-gap: 5px;
	padding-top: 5px;
	padding-right: 5px;
	background-color: rgba(0, 0, 0, 0);
	height: 200px;
}

.chat-tabs-list {
	background: #08080800;
	height: 100%;
	overflow-y: auto;
	text-align: center;
	text-overflow: ellipsis;
	display: grid;
	row-gap: 5px;
	padding-right: 5px;
	grid-row: 1 / 3;
	grid-auto-rows: 25px;
}

.chat-tab {
	position: relative;
	height: 25px;
	background-color: rgba(0, 0, 0, 0.4);
	/* background: #ffffff1e; */
	/* border-bottom: 1px solid hsla(0, 0, 75.3%, 0.2); */
	cursor: pointer;
	line-height: 25px;
	user-select: none;
	overflow-x: hidden;
}

.chat-tab > div {
	user-select: none;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.chat-tab-active {
	background: rgba(0, 225, 255, 0.171);
}

.chat-tab-group {
	background-color: rgba(0, 128, 28, 0.25);
}

.chat-tab-group.chat-tab-active {
	background-color: rgba(0, 255, 52, 0.3);
}

.chat-tab-whisper {
	background-color: rgba(128, 0, 128, 0.25);
}

.chat-tab-whisper.chat-tab-active {
	background-color: rgba(255, 0, 255, 0.3);
}

.chat-tab-icon {
	position: absolute;
	top: 10%;
	left: 2px;
	width: 20px;
	height: 20px;
}

.chat-message {
	word-wrap: break-word;
	user-select: text;
}

.chat-message.deleted > .chat-message-content {
	text-decoration: line-through;
	color: gray;
}

.chat-message.deleted .chat-item {
	text-decoration: line-through;
	color: gray;
	background-image: url('/images/ui/frame_box.png'), linear-gradient(rgb(28, 32, 36), rgb(28, 32, 36));
}

/* .chat-message > span {
	user-select: text !important;
} */

.chat-channel-function {
	font-size: 25px;
	height: 25px;
	width: 25px;
	margin-left: 10px;
	float: right;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	user-select: none;
}

body ::-webkit-scrollbar {
	width: 3px;
}

body ::-webkit-scrollbar-thumb {
	background: rgb(165, 165, 165);
}

body ::-webkit-scrollbar-thumb:hover {
	background: white;
}

.chat-message-list:hover ~ .chat-channels-functions > .chat-channel-function,
.chat-channels-functions:hover > .chat-channel-function {
	opacity: 1;
}

.chat-channel-function:hover {
	opacity: 1;
	transform: scale(1.1);
	cursor: pointer;
}

.chat-inner-container {
	height: 100%;
	border-image-source: /* linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.05)), */ url(/images/ui/stone-9slice-dark-gray.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 70px !important;
	border-image-outset: 0 !important;
	border-image-repeat: round !important;
	padding: 20px;
	position: relative;
	overflow: hidden;
}

.chat-public-channel-list {
	width: 50vw;
	max-width: 400px;
	height: 400px;
}

.chat-public-channel-list {
	width: 50vw;
	max-width: 400px;
	height: 400px;
	display: grid;
	grid-auto-rows: 40px;
	row-gap: 2px;
}

.chat-public-channel-list-item {
	background: #1a1a1a !important;
	border-color: #848484 #636363 #636363 #848484 !important;
	border-style: solid !important;
	border-width: 2px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	color: #fff;
	padding-top: 0 !important;
	padding-bottom: 0 !important;

	display: grid !important;
	grid-template-columns: 1fr 0.3fr;
	grid-template-rows: 36px;
}

.system-message {
	color: grey;
}

/* Red and bold, larger font size */
.system-warning {
	color: red;
	font-weight: bold;
	font-size: 2em;
}

.discord-message .chat-message-username {
	color: lightblue;
}

.message-shadow-mute .chat-message-content {
	color: grey;
	filter: grayscale(1);
}

.chat-message-username:hover {
	text-decoration: underline;
	cursor: pointer;
	user-select: none;
}

.chat-message-tooltip {
	display: grid;
	text-align: center;
	padding: 25px 15px 20px 15px;

	border-image-source: url(/images/ui/stone-9slice-dark-gray.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: auto !important;
	border-image-outset: 0 !important;
	/*     padding: 50px!important; */
	border-image-repeat: round !important;
	min-width: 150px;

	border-radius: 20px;
}

.chat-message-tooltip .decorative-line {
	margin-top: 3px;
	margin-bottom: 3px;
	filter: grayscale(100%);
}

.chat-message-tooltip-action {
	padding-top: 4px;
	padding-bottom: 4px;
	cursor: pointer;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	color: rgb(240, 240, 240);
	user-select: none;
	transition: transform 0.05s;
	min-width: 30px;
}

.chat-message-tooltip-action:hover {
	box-shadow: 3px 3px 5px 0 #000000, inset 4px 4px 15px 0 #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
	transform: scale(1.1);
}

.chat-message-tooltip-options:hover > .chat-message-tooltip-secondary-actions {
	display: flex;
}

.chat-tab-close-button {
	position: absolute;
	right: 4px;
	top: 4px;
	width: 18px;
	height: 18px;
	opacity: 0.3;
}

.chat-tab-close-button:hover {
	opacity: 1;
}
.chat-tab-close-button:before,
.chat-tab-close-button:after {
	position: absolute;
	left: 9px;
	content: ' ';
	height: 18px;
	width: 2px;
	background-color: #fff;
}
.chat-tab-close-button:before {
	transform: rotate(45deg);
}
.chat-tab-close-button:after {
	transform: rotate(-45deg);
}

.chat-skill-icon {
	width: 0.86em;
	height: 0.86em;
	object-fit: contain;
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.9));
	margin-top: -2px;
}

.chat-tab-unread-messages {
	position: absolute;
	right: 2px;
	top: 3px;
	width: 18px;
	height: 18px;
	background-color: rgba(0, 225, 255, 0.171);
	border-radius: 5px;
	color: gray;
	line-height: 18px;
	font-size: 14px;
}

.chat-tab-unread-mention {
	background-color: rgba(255, 0, 0, 0.356);
}

.chat-message-mention {
	background-color: rgba(255, 0, 0, 0.2);
}

.chat-message-moderator-delete {
	transition: transform 0.1s;
}

.chat-message-moderator-delete:hover {
	transform: scale(2);
	cursor: pointer;
}

.chat-icon-bronze {
	filter: drop-shadow(1px 0 0 rgba(205, 127, 50, 0.4)) drop-shadow(-1px 0 0 rgba(205, 127, 50, 0.4))
		drop-shadow(0 1px 0 rgba(205, 127, 50, 0.4)) drop-shadow(0 -1px 0 rgba(205, 127, 50, 0.4))
		drop-shadow(0 0 1px rgba(160, 82, 45, 0.4));
}

.chat-icon-silver {
	filter: drop-shadow(1px 0 0 rgba(192, 192, 192, 0.5)) drop-shadow(-1px 0 0 rgba(192, 192, 192, 0.5))
		drop-shadow(0 1px 0 rgba(192, 192, 192, 0.5)) drop-shadow(0 -1px 0 rgba(192, 192, 192, 0.5))
		drop-shadow(0 0 2px rgba(169, 169, 169, 0.6));
}

.chat-icon-gold {
	filter: drop-shadow(1px 0 0 rgba(255, 215, 0, 0.6)) drop-shadow(-1px 0 0 rgba(255, 215, 0, 0.6))
		drop-shadow(0 1px 0 rgba(255, 215, 0, 0.6)) drop-shadow(0 -1px 0 rgba(255, 215, 0, 0.6))
		drop-shadow(0 0 3px rgba(218, 165, 32, 0.7));
}

.chat-icon-platinum {
	filter: drop-shadow(1px 0 0 rgba(229, 228, 226, 0.7)) drop-shadow(-1px 0 0 rgba(229, 228, 226, 0.7))
		drop-shadow(0 1px 0 rgba(229, 228, 226, 0.7)) drop-shadow(0 -1px 0 rgba(229, 228, 226, 0.7))
		drop-shadow(0 0 4px rgba(185, 242, 255, 0.8));
}

.top-20-icon-normal {
	filter: drop-shadow(1px 0 0 rgba(255, 217, 0, 0.5)) drop-shadow(-1px 0 0 rgba(255, 217, 0, 0.5))
		drop-shadow(0 1px 0 rgba(255, 217, 0, 0.5)) drop-shadow(0 -1px 0 rgba(255, 217, 0, 0.5))
		drop-shadow(0 0 2px rgba(255, 217, 0, 0.5));
}

.top-20-icon-mastery {
	filter: drop-shadow(1px 0 0 rgba(173, 216, 230, 0.5)) drop-shadow(-1px 0 0 rgba(173, 216, 230, 0.5))
		drop-shadow(0 1px 0 rgba(173, 216, 230, 0.5)) drop-shadow(0 -1px 0 rgba(173, 216, 230, 0.5))
		drop-shadow(0 0 2px rgba(173, 216, 230, 0.5));
}

/* input:focus {
	border-bottom: none !important;
	box-shadow: none !important;
} */

@media only screen and (max-width: 390px) {
	.chat-container {
		font-size: 12px;
		grid-template-columns: minmax(130px, 25%) 1fr;
	}
}

@media only screen and (max-width: 300px) {
	.chat-container {
		font-size: 10px;
		grid-template-columns: minmax(110px, 25%) 1fr;
	}
}
