.theme-achievements {
	background-image: url('/images/pontification/shrine_background.png');
}
.achievement-container .quest-step {
	cursor: auto;
}

.achievement-container img {
	object-fit: contain;
}

.achievement-icon.Farm img {
	width: 200px;
	height: auto;
	left: 5px;
	margin: 0;
}
.achievement-icon.Cavern img {
	width: 120px;
	height: auto;
	top: 5px;
	left: 0px;
	margin: 0;
}
.achievement-icon.Internal img {
	width: 200px;
	height: auto;
	top: -15px;
	left: -70px;
	margin: 0;
}
.achievement-icon.Obsidian img {
	width: 250px;
	height: auto;
	top: -70px;
	left: -100px;
	margin: 0;
}
.achievement-icon.Demon img {
	width: 150px;
	height: auto;
	top: -10px;
	left: -30px;
	margin: 0;
}
.achievement-icon.Giant img {
	width: 150px;
	height: auto;
	top: -20px;
	left: -30px;
	margin: 0;
}
.achievement-icon.Cult img {
	width: 200px;
	height: auto;
	top: -15px;
	left: -60px;
	margin: 0;
}
.achievement-icon.Roll img {
	width: 70px;
	height: auto;
	top: -8px;
	left: -8px;
	filter: brightness(1.2) hue-rotate(145deg) saturate(3);
}
.achievement-icon.Re-Rolls img {
	filter: brightness(0.7) sepia(1) hue-rotate(10deg) saturate(5);
}
