.play-area-wrapper {
	position: relative;
	height: calc(100% - 30px);
	filter: brightness(1.1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.play-area-background {
	position: absolute;
	width: 100%;
	height: 100%;
	border-image-source: url(/images/ui/stone-9slice.png);
	border-image-slice: 100 fill;
	border-image-width: 70px;
	border-image-outset: 0;
	border-image-repeat: round;
	filter: brightness(1.1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.play-area {
	width: calc(100% - 26px);
	height: calc(100% - 26px);
	overflow-y: auto;
	position: relative;
	border-radius: 5px;
	padding: 10px;
	z-index: 0;
}

.mobile-layout .play-area {
	padding: 5px;
}

.theme-mining {
	background-image: url('/images/Background_triangle_mining_v2.svg');
}

.theme-crafting {
	background-image: url('/images/ui/crafting_background.png');
	transition: background 0.5s ease-out;
}

.theme-crafting::before {
	content: '';
	position: absolute;
	top: 13px;
	left: 13px;
	width: calc(100% - 26px);
	height: calc(100% - 26px);

	background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4));
}

.theme-foraging {
	background-image: url('/images/Background_triangle_woodcutting.svg');
}

.theme-farming {
	background-image: url('/images/Background_triangle_woodcutting.svg');
}

.theme-fishing {
	background-image: url('/images/Background_triangle_fishing.svg');
}

.theme-default {
	background-image: url('/images/background_basic.svg');
}

.theme-sessionStats {
	background-image: url('/images/landing_page_blurred.png');
	transition: background 0.5s ease-out;
}

.theme-shrine {
	background-image: url(/images/misc/shrine_1.png);
	background-size: cover;
}
