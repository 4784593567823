.tooltip-position-left .item-tooltip-flexbox {
	flex-direction: row-reverse;
}

.toolip-separator {
	margin-bottom: 5px;
}

.tooltip-original-name {
	font-style: italic;
	font-size: 11px;
}

.modern-tooltip {
	width: 320px;
	background: rgba(8, 13, 33, 0.95);
	font-family: Verdana, 'Open Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
	font-size: 13px;
	line-height: 17px;
	color: #fff;
	border: 3px solid #cfcfcf;
	border-radius: 10px;
	padding: 4px 8px;
}
.tooltip-columns {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.tooltip-columns > span:nth-child(even) {
	text-align: right;
}
.tooltip-columns > span {
	min-width: 34%;
}
.tooltip-name {
	font-size: 14px;
	line-height: 19px;
	font-weight: normal;
}
.tooltip-rarity-legendary {
	color: #ff8000;
}
.tooltip-rarity-epic {
	color: #a335ee;
}
.tooltip-rarity-rare {
	color: #0070dd;
}
.tooltip-rarity-uncommon {
	color: #1eff00;
}
.tooltip-rarity-junk {
	color: #515351;
}
/*.tooltip-stats {
    min-height: 5px;
}
.tooltip-stats-foodStats {
    margin-top: 5px;
}*/
.tooltip-combat-stat-header:empty,
.tooltip-item-set:empty,
.tooltip-item-set-effects-list:empty,
.tooltip-stats-foodStats:empty {
	margin-top: 0;
}
.tooltip-enchant,
.tooltip-item-skill,
.tooltip-set-item-equipped,
.tooltip-set-effect-active,
.tooltip-healing-use,
.tooltip-chest-use,
.tooltip-food-enchant {
	color: #1eff00;
}

.tooltip-stat-positive {
	color: #1eff00;
}

.tooltip-stat-negative {
	color: orange;
}

.legacy-tooltip-stat-positive {
	color: #2d2;
	text-shadow: -0.25px 0 green, 0 0.25px green, 0.25px 0 green, 0 -0.25px green;
}

.legacy-tooltip-stat-negative {
	color: #f55;
	text-shadow: -0.25px 0 red, 0 0.25px red, 0.25px 0 red, 0 -0.25px red;
}

.tooltip-enchant-unactive,
.tooltip-set-effect-inactive,
.tooltip-set-item-unequipped {
	color: #9d9d9d;
}
.tooltip-effects {
	color: #9d9d9d;
	margin-top: 5px;
}
.tooltip-flavor {
	color: #ffd100;
	font-style: italic;
	font-size: 11px;
}
.tooltip-item-augment-counter,
.tooltip-item-set-name {
	color: #ffd100;
}

.tooltip-ability-active {
	color: #0070dd;
}

.tooltip-item-augment {
	font-size: 11px;
}

.tooltip-item-set,
.tooltip-item-set-effects-list {
	margin-top: 10px;
}
.tooltip-item-set-item {
	margin-left: 10px;
}

.tooltip-not-tradeable,
.tooltip-requirement-unfulfilled {
	color: red;
}

.tooltip-yield-chance {
	font-size: 10px;
	color: #ffd100;
}

.tooltip-healing-use,
.tooltip-yield {
	margin-top: 5px;
}

.tooltip-combat-stat-header {
	font-size: 14px;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 5px;
}

.tooltip-compared-item {
	zoom: 80%;
}

.tooltip-premium-name {
	font-weight: bold;
	background-image: linear-gradient(
		to right,
		gold 0,
		#ffdc3e 22%,
		#ffe15b 45%,
		gold 50%,
		#ffe15b 55%,
		#ffdc3e 78%,
		gold 100%
	);
	background-clip: text;
	color: transparent;
	-webkit-background-clip: text;
	/* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.tooltip-item-affix {
	color: #ff89fa;
}
