.inventory-panel {
	min-height: 80px;
	max-height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.inventory-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	padding: 5px 10px 10px 5px;
	height: 100%;
	width: 100%;
	overflow-y: auto;
}

.vault-button {
	width: 40%;
	min-width: 200px;
	height: 35px;
	position: relative;
	bottom: 0;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	line-height: 25px;
	margin-left: auto;
	margin-right: auto;

	transition: filter 0.1s, transform 0.1s;
}

.vault-button:hover {
	transform: scale(1.05);
	filter: brightness(1.3);
}

.inventory-tab {
	width: 100%;
	max-width: 50%;
	height: 35px;
	position: relative;
	bottom: 0;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	line-height: 25px;
	margin-left: auto;
	margin-right: auto;

	transition: filter 0.1s, transform 0.1s;
}

.inventory-tab:hover {
	transform: scale(1.05);
	filter: brightness(1.3);
}

.multi-tabs {
	height: 100%;
	width: 100%;
	text-align: center;
}

.inventory-search-container {
	display: grid;
	grid-template-columns: 80% 10% 10%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.inventory-setting-icon {
	font-size: 33px;
	height: 100%;
	width: 100%;
	max-width: 50px;
	margin: auto;
	display: flex;
	cursor: pointer;
}
.inventory-setting-icon:hover {
	transform: scale(1.05);
	filter: brightness(1.3);
}

.inventory-tab-title {
	font-size: 20px;
}
