
.action-queue-icon {
	object-fit: contain;
    width: 30px;
    height: 30px;
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
    padding-right: 5px;
}

.action-queue-selector svg {
    min-width: 14px;
}

.action-queue-item {
	min-height: 40px;
	border-image-source: url(/images/ui/stone-9slice-dark-gray.png);
	border-image-slice: 100 fill;
	border-image-width: 40px;
	border-image-outset: 0;
	border-image-repeat: round;
    padding: 10px;
}

.queue-selected {
	border-image-source: url(/images/ui/stone-9slice-green-v3.png);
}

.queue-current {
	border-image-source: url(/images/ui/stone-9slice-yellow-v2.png);
}

.queue-locked-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10000;
}

.queue-locked-cover > div {
	position: relative;
	text-align: center;
	top: 30%;
  }
