.idlescape-confirm-alert {
	color: white !important;
}

.idlescape-confirm-alert.react-confirm-alert-overlay {
	background-color: rgba(0, 0, 0, 0.5);
}

.idlescape-confirm-alert .react-confirm-alert {
	border-image-source: url(/images/ui/stone-9slice.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 100px !important;
	border-image-outset: 0 !important;
	padding: 50px !important;
	border-image-repeat: round !important;
}

.idlescape-confirm-alert h1 {
	font-size: 1.25em;
	font-weight: 500;
	line-height: 1.6;
	border-bottom: 2px solid rgb(94, 94, 94);
	border-radius: 10px;
	text-align: center;
	color: white;
	display: block;
	margin-top: -30px !important;
}

.idlescape-confirm-alert .react-confirm-alert-body {
	background-color: transparent;
	color: white;
}

.idlescape-confirm-alert p {
	max-width: 500px;
	text-overflow: clip;
}
