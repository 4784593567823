.inspect-levels {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	width: 300px;
	text-align: center;
}

.inspect-level {
	height: 39px;
	/* background-color: rgba(0, 0, 0, 0.459); */
	/* box-shadow:0 5px 4px rgba(0, 0, 0, 0.3); */
	box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px, rgba(0, 0, 0, 0.07) 0 2px 4px, rgba(0, 0, 0, 0.07) 0 4px 8px,
		rgba(0, 0, 0, 0.07) 0 8px 16px, rgba(0, 0, 0, 0.07) 0 16px 32px, rgba(0, 0, 0, 0.07) 0 32px 64px;
	font-size: 26px;
	line-height: 40px;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.459), rgba(0, 0, 0, 0.459)),
		url(/images/ui/frame_box.png);
	background-size: 100% 100%;
	border-radius: 10px;
	z-index: 100;
}

.inspect-level img {
	margin-top: 4px;
	margin-left: 5px;
	height: 30px;
	width: 30px;
	float: left;
}
