.navbar1 {
	background-color: rgba(0, 0, 0, 0.3);
	/* border-bottom: 2px solid rgba(56, 56, 56, 0.3); */
	cursor: default;
	display: flex;
	justify-content: space-between;
	font-size: 25px;
	line-height: 35px;
	color: white;
	background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.3) 0,
			rgba(0, 0, 0, 0.3) 90%,
			rgba(56, 56, 56, 0.3) 100%
		),
		url(/images/ui/stone_ui_tile_dark_gray.png);
	filter: drop-shadow(0 0 0.45rem black);
	box-shadow: 0 0 4px 8px rgba(0, 0, 0, 0.493) inset;
	grid-area: navbar;
}

.navbar1-box {
	/* flex: 1; */
	text-align: center;
}

.navbar1-box.left {
	flex: 1.5;
	padding-left: 5px;
	text-align: left;
	text-overflow: ellipsis;
	transition: 0.25s;
	/* box-shadow: 0 0 4px 4px rgb(0, 0, 0) inset;
    clip-path: inset(0 -15px 0 0); */
}

.navbar1-box.right {
	flex: 1.5;
	padding-right: 10px;
	text-align: right;
	text-overflow: ellipsis;
	/* box-shadow: 0 0 4px 4px rgb(0, 0, 0) inset; */
}
/* .navbar1-box.right:hover {
	background: rgba(255, 255, 255, 0.24);
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.25) 100%);
} */

.navbar1-box.left:hover {
	background: rgba(255, 255, 255, 0.24);
	background: linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%);
}

.feedback-form-control {
	color: white !important;
	width: 100%;
	border: 1px solid silver !important;
	margin-bottom: 10px !important;
}
.feedback-form-control div,
.feedback-dialog textarea {
	color: white;
}

.feedback-dialog .MuiDialogContent-root {
	width: 100%;
}

.feedback-dialog textarea {
	height: 200px;
	/* max-width: 548px;
    min-width: 548px;
    max-height: 400px;
    min-height: 50px; */
}

.feedback-dialog .MuiPaper-root {
	width: 100% !important;
}

.donation {
	text-align: center;
	font-size: 30px;
	line-height: 90px;
	border: 1px solid silver;
	height: 120px;
	width: 290px;
	background: linear-gradient(to bottom, #485563, #29323c);
	cursor: pointer;
}

.donation:hover {
	opacity: 0.5;
	/* background: linear-gradient(to bottom, #0f2027, #203a43, #2c5364) !important; */
}

.donate-dialog .close-dialog-button {
	margin-right: 0 !important;
}

.donate-dialog p {
	text-align: center;
}

.donate-dialog input {
	min-width: 5% !important;
	max-width: 10% !important;
	/* 	margin-left: 20px !important;
	margin-right: 20px !important; */
}

.donation:first-child {
	background-image: url('/images/donation/stripe_logo.png');
	background-size: cover;
}

.red-font {
	color: red;
}

.yellow-font {
	color: yellow;
}

.theme-font {
	color: #2c5364;
}

.popup-dialog .MuiPaper-root {
	/*     border: 25px solid transparent !important; */
	/* padding: 15px !important; */
	border-image-source: url('/images/ui/stone-9slice.png') !important;
	border-image-slice: 100 fill !important;
	border-image-width: 100px !important;
	border-image-outset: 50px !important;
	border-image-repeat: round !important;
}

.header-level-container {
	display: flex;
}

@media (max-width: 700px) {
	.navbar1 {
		font-size: 20px;
	}
}

@media (max-width: 600px) {
	.navbar1 {
		font-size: 16px;
	}
}

.book-dialog p {
	line-height: 1.6;
	margin-bottom: 20px;
}

.offline-progress-box {
	background-color: rgba(0, 0, 0, 0.459);
	box-shadow: 0 5px 4px rgba(0, 0, 0, 0.3);
	height: auto;
	width: 100%;
	margin-bottom: 10px;
}

.offline-progress-box.all-items {
	padding: 10px 6px;
	overflow: auto;
}

.offline-progress-box-experience img {
	height: 30px;
}

.offline-progress-box-experience ul {
	padding-top: 10px;
}

.offline-progress-box-experience li {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-size: 25px;
}

.chest-open-box {
	background-color: rgba(0, 0, 0, 0.459);
	box-shadow: 0 5px 4px rgba(0, 0, 0, 0.3);
	height: auto;
	width: 100%;
	margin-bottom: 10px;
}

.share-chest-button {
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	width: 50px;
	height: 55px;
	margin-left: auto;
	margin-top: -55px;
	padding-bottom: 5px;
}

.share-chest-button:hover {
	filter: brightness(2);
}

.header-league-icon {
	height: 32px;
	width: 32px;
	margin-left: 5px;
	margin-bottom: 2px;
}

.header-unclaimed:hover {
	font-weight: bold;
	cursor: pointer;
	text-decoration: underline;
}

.claim-account-dialog input {
	width: 300px !important;
	color: white !important;
}

.claim-account-dialog label {
	color: white !important;
}

.player-counter {
	position: relative;
}

.player-counter .header-league-icon {
	margin-right: 10px;
}

.player-counter-tooltip img {
	margin-right: 10px;
}
