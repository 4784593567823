.crafting-tab-container {
	display: grid !important;
	max-height: 250px;
	width: 100%;
	overflow: hidden auto;
	position: relative;
	font-size: clamp(16px, 1vw, 20px);
	grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
	grid-template-rows: repeat(auto-fit, 1.5em);
	grid-row-gap: 2px;
	min-height: 0;
}

@media (max-width: 1200px) {
	.crafting-tab-container {
		grid-template-columns: repeat(auto-fill, minmax(100px, 33%));
	}
}

.crafting-tab-icon {
	object-fit: contain;
	width: 1.2em;
	height: 1.2em;
	margin-left: 0.1em;
	margin-right: 0.1em;
	filter: drop-shadow(0 0 2px black);
}

.crafting-tab.text {
	overflow: hidden;
	white-space: nowrap;
}

.crafting-tab {
	max-width: 250px;
	padding-right: 10px;
	padding-left: 10px;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.582), rgba(0, 0, 0, 0.329)),
		url(/images/ui/frame_panel_light.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 10px;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: transform 0.05s;
	font-size: inherit;
	height: 1.5em;
	overflow: hidden;
}

.crafting-container {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	overflow: auto;
}

.crafting-recipe {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	position: relative;
	height: 100%;
	overflow-y: auto;
}

.crafting-categories {
	display: grid;
	grid-gap: 0.25rem;
	min-width: 130px;
	height: 100%;
	flex: 1 1;
	justify-content: center;
	overflow-y: auto;
	grid-template-rows: repeat(auto-fill, 60px);
	grid-template-columns: repeat(auto-fill, 60px);
}

.mobile-layout .crafting-categories {
	grid-template-columns: repeat(auto-fill, 50px);
	grid-template-rows: repeat(auto-fill, 50px);
}

.recipe-item-level {
	position: absolute;
	bottom: 0;
	text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
	background-color: #0003;
	border-radius: 5px;
	padding: 0 2px;
	text-align: end;
}

.insufficient-level {
	text-shadow: -1px 0 #da0707, 0 1px #da0707, 1px 0 #da0707, 0 -1px #da0707;
}

.crafting-container .idlescape-container {
	overflow: auto;
}

.mobile-layout .crafting-recipe {
	display: block;
}

.mobile-layout .crafting-tab {
	font-size: clamp(12px, 3vw, 16px);
	height: 24px;
	padding-left: 5px;
	padding-right: 5px;
}

.mobile-layout .crafting-tab > div {
	vertical-align: middle;
	display: inline;
}

.crafting-locked-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10000;
}

.crafting-locked-cover div {
	position: relative;
	text-align: center;
	font-size: 20px;
	top: 30%;
}
