.item {
	width: 60px;
	height: 60px;
	float: left; /* ? Is this needed? */
	cursor: pointer;
	position: relative;
	color: white;
	font-size: 20px;
	border-radius: 8px !important;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	background-size: 100% 100%;
	transition: transform 0.1s;
}

.mobile-layout .item {
	width: 50px;
	height: 50px;
	font-size: 16px;
}

.item:hover {
	transform: scale(1.05);
}

.item-animating-gain {
	animation: glow-gain 1000ms linear infinite alternate;
}
.item-animating-lose {
	animation: glow-lose 1000ms linear infinite alternate;
}
.item-animating-pink {
	animation: glow-pink 500ms ease-out;
}
.high-performance .item-animating-gain,
.high-performance .item-animating-lose,
.high-performance .item-animating-pink {
	animation: none;
}

@keyframes glow-gain {
	0% {
		box-shadow: 0 0 0 rgba(16, 1, 156, 0.2), inset 0 0 0 rgba(4, 1, 156, 0.1);
	}
	100% {
		box-shadow: 0 0 5px 3px rgb(55, 131, 59), inset 0 0 2px 5px rgb(55, 131, 59);
	}
}

@keyframes glow-lose {
	0% {
		box-shadow: 0 0 0 rgba(16, 1, 156, 0.2), inset 0 0 0 rgba(4, 1, 156, 0.1);
	}
	100% {
		box-shadow: 0 0 5px 3px rgb(131, 55, 55), inset 0 0 2px 5px rgb(133, 52, 52);
	}
}

@keyframes glow-pink {
	0% {
		box-shadow: 0 0 0 rgba(16, 1, 156, 0.2), inset 0 0 0 rgba(4, 1, 156, 0.1);
	}
	100% {
		box-shadow: 0 0 5px 3px rgb(208, 108, 238), inset 0 0 2px 5px rgb(208, 108, 238);
	}
}

/*
=========== CHRISTMAS EVENT =============

body {
  background-image: radial-gradient(#212121 20%, transparent 20%),
      radial-gradient(#fafafa 20%, transparent 20%);
  background-color: #e53935;
  background-position: 0 0, 50px 50px;
  background-size: 100px 100px;
}

*/

.christmas-food {
	background-image: repeating-linear-gradient(45deg, #0095ff, #0095ff 10px, #0061a6 10px, #0061a6 20px);

	border: 1px solid #03538c;
}

.christmas-drop {
	background-image: repeating-linear-gradient(45deg, #146b3a, #146b3a 10px, #165b33 10px, #165b33 20px);

	border: 1px solid #eaf244;
}

@keyframes blink {
	50% {
		box-shadow: 0 0 15px #f23838;
		border-color: #e61515;
	}
}
.christmas-present {
	background-color: #f3f700ab;

	border: 1px solid black;
}

.christmas-globe {
	background-color: #cfe3ff91;

	border: 1px solid #5198fc;
	box-shadow: 0 0 12px #78aefa;
}

.christmas-tier1 {
	background-color: #ff363694;

	border: 2px solid #c71c1c;
}
.christmas-tier2 {
	background-image: repeating-linear-gradient(45deg, #ec2d2db0, #ec2d2db0 10px, #b41313a1 10px, #b41313a1 20px);

	border: 2px solid #c71c1c;
}
.christmas-tier3 {
	background-image: repeating-linear-gradient(45deg, #8f0101, #8f0101 10px, #360505 10px, #360505 20px);

	border: 1px solid #8f0101;
}

/*
=========================================
*/

.gold {
	background-color: #64503d8f;
	border: 2px solid #ffda7c48;
}

.heat {
	background-color: #a02c2c8f;
	border: 2px solid #ff000048;
}

.ore {
	/* background-color: rgba(56, 19, 1, 0.7);
	border: 2px solid rgba(153, 54, 4, 0.2); */

	background-image: linear-gradient(to bottom, rgba(56, 19, 1, 0.7), rgba(56, 19, 1, 0.7)),
		url(/images/ui/frame_box.png);
}

.fiber {
	/* 	background-color: rgba(39, 39, 39, 0.7);
	border: 2px solid rgba(99, 99, 99, 0.2); */

	background-image: linear-gradient(to bottom, rgba(60, 71, 82, 0.2), rgba(104, 159, 96, 0.7)),
		url(/images/ui/frame_box.png);
}

.cloth {
	/* 	background-color: rgba(39, 39, 39, 0.7);
	border: 2px solid rgba(99, 99, 99, 0.2); */

	background-image: linear-gradient(to bottom, rgba(60, 71, 82, 0.2), rgba(49, 83, 45, 0.7)),
		url(/images/ui/frame_box.png);
}

.fur {
	/* 	background-color: rgba(39, 39, 39, 0.7);
	border: 2px solid rgba(99, 99, 99, 0.2); */

	background-image: linear-gradient(to bottom, rgba(32, 28, 25, 0.2), rgba(96, 82, 51, 0.7)),
		url(/images/ui/frame_box.png);
}

.leather {
	/* 	background-color: rgba(39, 39, 39, 0.7);
	border: 2px solid rgba(99, 99, 99, 0.2); */

	background-image: linear-gradient(to bottom, rgba(32, 28, 25, 0.2), rgba(62, 56, 43, 0.7)),
		url(/images/ui/frame_box.png);
}

.bar {
	/* 	background-color: rgba(39, 39, 39, 0.7);
	border: 2px solid rgba(99, 99, 99, 0.2); */

	background-image: linear-gradient(to bottom, rgba(39, 39, 39, 0.7), rgba(39, 39, 39, 0.7)),
		url(/images/ui/frame_box.png);
}

.log {
	/*     background-color: rgba(6, 53, 8, 0.7);
    border: 2px solid rgba(22, 114, 14, 0.2); */

	background-image: linear-gradient(to bottom, rgba(6, 53, 8, 0.7), rgba(6, 53, 8, 0.7)),
		url(/images/ui/frame_box.png);
}

.seed {
	/*     background-color: rgba(13, 95, 17, 0.7);
    border: 2px solid rgba(35, 172, 22, 0.2); */

	background-image: linear-gradient(to bottom, rgba(13, 95, 17, 0.6), rgba(13, 95, 17, 0.6)),
		url(/images/ui/frame_box.png);
}

.equipment {
	/* background-color: rgba(87, 104, 253, 0.01);
	border: 2px solid rgba(255, 238, 0, 0.1); */

	background-image: linear-gradient(to bottom, rgba(87, 104, 253, 0.199), rgba(87, 104, 253, 0.199)),
		url(/images/ui/frame_box.png);
}

.gem {
	/*     background-color: rgba(34, 211, 255, 0.37);
    border: 2px solid rgba(79, 97, 255, 0.2); */

	background-image: linear-gradient(to bottom, rgba(34, 211, 255, 0.37), rgba(34, 211, 255, 0.37)),
		url(/images/ui/frame_box.png);
}

.elite-scroll {
	/*     background-color: rgba(34, 211, 255, 0.37);
        border: 2px solid rgba(79, 97, 255, 0.2); */

	background-image: linear-gradient(to bottom, rgba(151, 63, 22, 0.37), rgba(226, 72, 16, 0.37)),
		url(/images/ui/frame_box.png);
}

.junk {
	/*     background-color: rgba(34, 211, 255, 0.37);
        border: 2px solid rgba(79, 97, 255, 0.2); */

	background-image: linear-gradient(to bottom, rgba(149, 149, 149, 0.37), rgba(65, 65, 65, 0.37)),
		url(/images/ui/frame_box.png);
}

.rune {
	/* background-color: rgba(61, 61, 61, 0.37); */

	/*     background: radial-gradient(circle, rgba(110, 180, 255, 0.486) 0, rgba(61, 61, 61, 0.37) 100%);
    border: 2px solid rgba(139, 139, 139, 0.2); */

	background-image: radial-gradient(circle, rgba(110, 180, 255, 0.486) 0, rgba(61, 61, 61, 0.37) 100%),
		url(/images/ui/frame_box.png);
}

.fish {
	/*     background-color: rgba(65, 147, 255, 0.37);
    border: 2px solid rgba(0, 102, 255, 0.2); */

	/* background-image: linear-gradient(to bottom, rgba(65, 147, 255, 0.37), rgba(65, 147, 255, 0.37)), url(/images/ui/frame_box.png); */
	background-image: linear-gradient(to bottom, rgba(64, 224, 208, 0.37), rgba(64, 224, 208, 0.37)),
		url(/images/ui/frame_box.png);
}

.fish-secondary {
	/*     background-color: rgba(65, 147, 255, 0.37);
    border: 2px solid rgba(0, 102, 255, 0.2); */

	/* background-image: linear-gradient(to bottom, rgba(65, 147, 255, 0.37), rgba(65, 147, 255, 0.37)), url(/images/ui/frame_box.png); */
	background-image: linear-gradient(to bottom, rgba(64, 200, 224, 0.37), rgba(64, 173, 224, 0.37)),
		url(/images/ui/frame_box.png);
}

.cooking-ingredient {
	/*     background-color: rgba(65, 147, 255, 0.37);


    border: 2px solid rgba(0, 102, 255, 0.2); */

	background-image: linear-gradient(to bottom, rgba(65, 147, 255, 0.37), rgba(65, 147, 255, 0.37)),
		url(/images/ui/frame_box.png);
}

/* .chat-item.platinum {
	background-image: linear-gradient(to bottom, rgba(0, 136, 199, 0), rgb(0, 136, 199)), url(/images/ui/frame_box.png);
} */

.cookedFish {
	/*     border: 2px solid #7755226c;
    background-color: #d29a4525; */

	background-image: linear-gradient(to bottom, #d29a4525, #d29a4525), url(/images/ui/frame_box.png);
}

.enchanted-scroll {
	/*     background: radial-gradient(circle, rgba(235, 16, 255, 0.486) 0, rgba(61, 61, 61, 0.37) 100%);
    border: 2px solid rgba(247, 0, 255, 0.2); */

	background-image: linear-gradient(to bottom, rgba(56, 19, 1, 0.7), rgba(56, 19, 1, 0.7)),
		url(/images/ui/frame_box.png);
}

.ammo {
	background-image: linear-gradient(to bottom, rgba(176, 184, 255, 0.199), rgba(126, 139, 255, 0.199)),
		url(/images/ui/frame_box.png);
}

.gold-medal {
	/*     background: radial-gradient(circle, rgba(217, 255, 0, 0.719) 0, rgba(251, 255, 0, 0.192) 100%);
    border: 2px solid rgba(241, 255, 47, 0.2); */

	background-image: radial-gradient(circle, rgba(217, 255, 0, 0.719) 0, rgba(251, 255, 0, 0.192) 100%),
		url(/images/ui/frame_box.png);
}

.silver-medal {
	/*     background: radial-gradient(circle, rgba(255, 255, 255, 0.719) 0, rgba(44, 44, 44, 0.192) 100%);
    border: 2px solid rgba(161, 161, 161, 0.2); */

	background-image: radial-gradient(circle, rgba(255, 255, 255, 0.719) 0, rgba(44, 44, 44, 0.192) 100%),
		url(/images/ui/frame_box.png);
}

.bronze-medal {
	/*     background: radial-gradient(circle, rgba(124, 95, 0, 0.719) 0, rgba(112, 82, 0, 0.192) 100%);
    border: 2px solid rgba(156, 115, 0, 0.2); */

	background-image: radial-gradient(circle, rgba(124, 95, 0, 0.719) 0, rgba(112, 82, 0, 0.192) 100%),
		url(/images/ui/frame_box.png);
}

.platinum {
	background-image: linear-gradient(
			to bottom,
			rgba(0, 225, 255, 0) 0,
			rgba(0, 225, 255, 0) 20%,
			rgba(0, 225, 255, 0.4) 90%,
			rgba(0, 225, 255, 0.6) 100%
		),
		url(/images/ui/frame_box.png);
}

.key {
	border: 2px solid #7755226c;
	background-color: #d29a4525;
}

.item-icon {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	padding: 5% !important;
	object-fit: contain;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	box-sizing: border-box;
}

.sell-item-dialog .MuiPaper-root {
	background-color: rgba(0, 0, 0, 0.19);
	border: 2px solid silver;
	color: white;
	align-items: center;

	border-image-source: url('/images/ui/stone-9slice.png') !important;
	border-image-slice: 100 fill !important;
	border-image-width: 100px !important;
	border-image-outset: 0 !important;
	padding: clamp(30px, 7%, 50px);
	margin: auto;
	border-image-repeat: round !important;
	overflow-x: hidden;
	z-index: 0;
}

.sell-item-dialog .MuiDialogTitle-root,
.tooltip-title {
	border-bottom: 2px solid rgb(94, 94, 94);
	border-radius: 10px;
	padding: 16px 24px 0 24px;
	margin-bottom: 10px;
}

.sell-item-dialog .MuiDialogContent-root {
	width: 100%;
}

.sell-item-dialog .MuiDialogContentText-root {
	color: white;
	align-self: center !important;
	margin-bottom: 5px;
}

.sell-item-dialog input {
	color: white;
}

.close-dialog-button {
	margin-right: 40px !important;
}

.item-input {
	padding-top: 0;
}

.item-dialogue-button-div {
	width: 100%;
	flex-direction: column;
	justify-content: center;
}

.item-dialogue-button-div div {
	width: 250px;
	margin: 5px;
}

.item-dialogue-button {
	width: 100%;
}

.tooltip-text-image.left {
	float: left;
}

.tooltip-text-image.right {
	float: right;
}

.tooltip-text-image > img {
	vertical-align: middle;
}

.item-tooltip span {
	/* display: block; */
	font-size: 12px;
	line-height: 12px;
}

.item-tooltip {
	border-image-source: url(/images/ui/stone-9slice.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 50px !important;
	border-image-outset: 0 !important;
	/*     padding: 50px!important; */
	border-image-repeat: round !important;

	pointer-events: none;
	z-index: 1000;
	font-size: small;
	width: 320px;
	overflow: auto;
	background: #191919be;
	position: relative;
	line-height: 1.25em;
}

.item-tooltip hr {
	border-top: 1px solid gray;
	margin: 4px 0 -0.75em;
	padding: 0;
	clear: both;
}

.item-tooltip-image-container {
	float: right;
	margin-bottom: 8px;
	font-size: 0;
	width: 55px;
	display: flex;
	justify-content: center;
	height: auto;
	overflow: hidden;
}
.item-tooltip-image {
	width: auto;
	max-width: 55px;
	height: auto;
	max-height: 55px;
}
.item-tooltip .combat-ability {
	height: 60px !important;
	line-height: 12px;
}
.item-tooltip .combat-ability-icon {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.flavor-text {
	color: #bfbfbf;
}

.item-stat-block {
	width: 100%;
	float: none;
}

.item-enchant-text {
	margin-bottom: -10px;
}

.item-enchant-slot-container {
	width: 100% !important;
	height: 20px;
	display: flex;
	flex-wrap: wrap;
	row-gap: 5px;
	justify-content: center;
	margin-bottom: -13px;
}

.item-enchant-slot-slot {
	position: relative;
	width: 28px;
	height: 20px;
}

.item-enchant-slot-image {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0;
}

.enchanted-description {
	padding-bottom: 6px;
}

.item-stats {
	display: grid;
	grid-template-columns: 45% 45%;
	grid-gap: 2px;
}

.item-stat {
	color: lightgrey;
	width: 100%;
	font-size: 12px;
	text-align: left;
}

.item-locked {
	border: 2px solid aqua;
}

.augmentation-glow-background {
	height: 100%;
	width: 100%;
	filter: brightness(500%) blur(10px) sepia(100%) !important;
}

.idlescape-button {
	height: 40px;
	width: 100%;
	padding: 6px 16px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-size: 100% 100%;
	margin-top: 0;
	margin-bottom: 0;
	cursor: pointer;
}

.idlescape-button[disabled] {
	pointer-events: none;
	filter: grayscale(1);
}

.idlescape-button:hover {
	filter: brightness(1.5);
}

.idlescape-button-red {
	background-image: url('/images/ui/stone_button_red.png');
}

.idlescape-button-blue {
	background-image: url('/images/ui/stone_button_blue.png');
}

.idlescape-button-green {
	background-image: url('/images/ui/stone_button_green.png');
}

.idlescape-button-gray {
	background-image: url('/images/ui/stone_button_gray.png');
}

.idlescape-button-orange {
	background-image: url('/images/ui/stone_button_orange.png');
}

.MuiDialogActions-spacing {
	margin-left: 0 !important;
}

.item-augment {
	position: absolute;
	top: 0;
	right: 2px;
	color: rgb(219, 219, 0);
	font-weight: 1000;
	font-size: 12px;
	line-height: 14px;
	/* -webkit-text-fill-color: yellow; */
	/* -webkit-text-stroke-width: 1px; */ /*
    -webkit-text-stroke-color: rgb(122, 122, 0); */
}

.item-enchant {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
}

.item-enchant-inventory {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 18px;
	box-sizing: content-box !important;
}

.item-enchant img {
	width: 100%;
	height: 100%;
	vertical-align: top;
}

.cooking-ingredient .item {
	width: 90%;
	height: 90%;
	margin: 5%;
}
