.resource-list {
	display: grid; /* 1 */
	grid-template-columns: repeat(auto-fill, 240px); /* 2 */
	grid-gap: 1rem; /* 3 */
	justify-content: center; /* 4 */
}

.resource-container {
	/* filter: drop-shadow(0 0 0.45rem black); */
	box-shadow: 0 0 3px 3px rgb(21 23 27) inset;
	height: 240px;
	width: 240px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	border-radius: 10px;
	aspect-ratio: 1;
}

.resource-container-image {
	height: calc(100% - 125px);
	margin-left: auto;
	margin-right: auto;
	display: block;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.resource-container-progress {
	overflow: hidden;
	background-color: transparent !important;
	height: 10px !important;
}

.resource-container-progress-bar {
	will-change: transform;
	background-color: rgba(83, 42, 22, 0.9);
	border-radius: 20px !important;
	transition-duration: 0.25s !important;
	transition-timing-function: linear !important;
	box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, 1);
}

.scrolling-text {
	position: absolute;
	left: 0;
	right: 0;
	top: 55%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	vertical-align: middle;
	width: 200px;
	font-size: 25px;
	opacity: 0;
	display: none;
}

.scrolling-text-active {
	animation: scrolling-text-animation 1s infinite;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	display: block;
}

.high-performance .scrolling-text-active {
	animation: none;
}

@keyframes scrolling-text-animation {
	from {
		opacity: 1;
	}
	to {
		transform: translateY(-150px);
		opacity: 0.3;
	}
}

.resource-container-button {
	border-radius: 0 0 10px 10px;
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center !important;
}

.resource-container-button button {
	width: 100%;
	height: 30px;
	box-shadow: none;
	z-index: -100;
	font: 14px 'Exo 2', sans-serif;
	margin-top: 3px;
}

.resource-container-button button:focus {
	outline: 0;
}

.icon24 {
	height: 24px;
	width: 24px;
}

.icon16 {
	height: 16px;
	width: 16px;
	vertical-align: top;
	margin-right: 2px;
}

.icon10 {
	height: 10px;
	width: 10px;
	vertical-align: middle;
}

.resource-time {
	float: right !important;
	font-size: 12px;
	margin-right: 5px !important;
}

.resource-property {
	padding: 3px;
	border-radius: 10px;
	margin-bottom: 2px;
	float: left;
	margin-right: 2px;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.resource-node-time-tooltip {
	display: flex;
	align-items: center;
	position: relative;
}

.mobile-layout .resource-node-time-tooltip > span:nth-child(2) {
	text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
	min-width: 24px;
	text-align: center;
	z-index: 1;
}

.mobile-layout .resource-node-time-tooltip > img {
	position: absolute;
}

.resource-container-tooltip {
	max-width: 250px;
	text-align: center;
}

.resource-level {
	margin-left: 5px;
}

.mining-container .resource-property {
	background-color: rgba(56, 19, 1, 0.2);
	border: 2px solid rgba(153, 54, 4, 0.2);
}

.mining-container .resource-button {
	background-color: rgba(56, 19, 1, 0.7);
}

.mining-container .resource-button:hover {
	box-shadow: 0 0 8px rgba(255, 85, 0, 0.9);
	background-color: rgba(116, 39, 0, 0.7);
}

.mining-container .resource-container-progress-bar {
	background-color: rgba(83, 42, 22, 0.9) !important;
}

.foraging-container .resource-property {
	background-color: rgba(3, 56, 1, 0.2);
	border: 2px solid rgba(16, 153, 4, 0.2);
}

.foraging-container .resource-button {
	background-color: rgba(1, 20, 2, 0.7);
	/* border-top: 2px solid rgba(0, 85, 11, 0.9); */
}

.foraging-container .resource-button:hover {
	box-shadow: 0 0 8px rgba(0, 138, 0, 0.9);
	background-color: rgba(0, 90, 0, 0.7);
}

.foraging-container .resource-container-progress-bar {
	background-color: rgba(27, 83, 22, 0.9) !important;
}

.fishing-container .resource-property {
	background-color: rgba(0, 95, 112, 0.2);
	border: 2px solid rgba(0, 195, 255, 0.2);
}

.fishing-container .resource-button {
	background-color: rgba(0, 59, 77, 0.7);
	/* border-top: 2px solid rgba(0, 114, 122, 0.9); */
}

.fishing-container .resource-button:hover {
	box-shadow: 0 0 8px rgba(59, 112, 255, 0.9);
	background-color: rgba(0, 183, 255, 0.7);
}

.fishing-container .resource-container-progress-bar {
	background-color: rgba(0, 114, 122, 0.9) !important;
}

.fishing-container .resource-container-image {
	visibility: hidden;
}

/*=========================================================================*/

.lockout-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 1;
	padding: 10%;
}

.lockout-cover p {
	top: 30%;
	text-align: center;
	font-size: 20px;
}

.lockout-cover span {
	position: absolute;
	width: 80%;
	bottom: 25%;
	text-align: center;
	font-size: 15px;
}

.lockout-progress-container {
	position: absolute;
	width: 80%;
	height: 20px;
	bottom: 10%;
	background-color: gray;
}

.lockout-fighting-health {
	position: absolute;
	top: 0;
	text-shadow: 1px 1px #000000;
	text-align: center;
	height: 100%;
	width: 100%;
}

.lockout-health-bar {
	position: absolute;
	top: 0;
	background-color: green;
	height: 100%;
}

.lockout-progress-bar {
	position: absolute;
	top: 0;
	background-color: lightgrey;
	height: 100%;
}

/*=========================================================================*/

.locked-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 1;
}

.locked-cover div {
	position: relative;
	text-align: center;
	font-size: 40px;
	top: 30%;
	margin: 0;
}

.locked-cover div img {
	margin-right: 5px;
}

.locked-cover div:first-child {
	right: 0;
	font-size: 20px;
	margin-bottom: 15px;
}

.mobile-layout .locked-cover div {
	font-size: 18px;
	top: 20%;
}

.icon60 {
	height: 60px;
	width: 60px;
}

.resource-node-tooltip-container {
	width: 24px;
	height: 24px !important;
	position: absolute;
	right: 5px;
	top: 5px;
	cursor: pointer;
}

#resource-node-tooltip {
	width: 24px;
	height: 24px !important;
	position: absolute;
	background-color: rgba(128, 128, 128, 0);
	color: white;
	white-space: pre-wrap;
	z-index: 100;
}

.MuiTooltip-popper {
	font-size: 50px !important;
}

.resource-required-resources {
	width: 100%;
	padding-left: 5px;
	position: absolute;
	bottom: 30px;
}

.resource-button {
	border-radius: 0 0 10px 10px;
	box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0 -2px 6px 0 inset;
	color: white;
	border: 0;
	cursor: pointer;
}

.blue-text {
	color: rgb(0, 0, 143);
}

.resource-container-tooltip .resource-container-loot-title {
	text-align: center;
	font-size: 22px;
}

.resource-container-resource-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: auto;
	column-gap: 5px;
	row-gap: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.resource-container-resource-list .centered {
	font-size: 16px;
}

.resource-container-resource-aph {
	text-align: center;
}

.mobile-layout .resource-list {
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
	grid-gap: 0.5rem;
}

.mobile-layout .resource-container {
	width: 100%;
	height: 100%;
}

.mobile-layout .foraging-info h2,
.mobile-layout .fishing-info h2 {
	margin: 5px 0;
}

.action-queue .resource-container-button .resource-button {
	width: 50%;
}
.action-queue .resource-button.add-queue-button {
	background-color: #000b;
}
.resource-container-button .btn {
	border: none;
}
