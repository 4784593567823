.foraging-info {
	min-height: auto;
	text-align: center;
	justify-self: center;
	max-width: 400px;
	width: 100%;
	border-image-outset: 10px !important;
	padding: 20px !important;
}
.foraging-info > p {
	padding: 0 20px 20px;
	max-width: 425px;
	max-height: 500px;
	overflow-y: auto;
	line-height: 20px;
	margin-top: 10px;
	font-size: 14px;
}

.mobile-layout .foraging-info p {
	padding: 0;
}
