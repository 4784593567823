.inventory-equipment .inventory-item {
	background: linear-gradient(to bottom, #0f2027, #203a43, #2c5364) !important;
	position: relative;
}
.inventory-container .inventory-item button {
	position: absolute;
	width: 100%;
	height: 25px;
	bottom: 0;
	border-radius: 0;
	line-height: 25px;
	background: linear-gradient(to bottom, #52c234, #175700);
	font: 14px 'Exo 2', sans-serif;
}
.inventory-container .inventory-equipment .inventory-item button {
	background: linear-gradient(to bottom, #ed213a, #93291e);
	font: 14px 'Exo 2', sans-serif;
}

.inventory-item {
	object-fit: contain;
	float: left;
	position: relative;
	margin: 5px;
	border: 2px solid grey;
	height: 87px;
	width: 87px;
	cursor: pointer;
	background: linear-gradient(to bottom, #485563, #29323c);
}

.inventory-items img {
	height: 100%;
	width: 100%;
	object-fit: contain;
	padding: 10px;
}

.inventory-item-amount {
	position: absolute;
	font-size: 40px;
	top: 50%;
	left: 50%;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
