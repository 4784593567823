.inventory-equipment {
	margin-left: auto;
	margin-right: auto;
	padding: 0 20px 20px 20px;
	border: 2px solid black;
	border-radius: 40px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 0;
	background-color: rgba(51, 51, 51, 0.6);
}
.inventory-equipment > h3 {
	grid-column: 1/3;
	justify-self: center;
	margin-bottom: 0;
	margin-top: 0;
	font-size: 25px;
}
.inventory-equipment > div {
	background-color: black;
	width: 100px;
	height: 100px;
	border: 2px solid rgba(192, 192, 192, 0.2);
	background-color: rgba(51, 51, 51, 0.75);
}

.inventory-items {
	padding: 0 20px 20px 20px;
	border: 2px solid black;
	border-radius: 40px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 0;
	grid-row: 1/3;
	background-color: rgba(51, 51, 51, 0.6);
}

.inventory-items h3:first-child {
	grid-column: 1/3;
	justify-self: center;
	margin-bottom: 0;
}

.inventory-items h3 {
	grid-column: 1/3;
	justify-self: start;
	margin-bottom: 0;
	margin-top: 0;
	font-size: 25px;
}

.inventory-items > div {
	background-color: black;
	height: auto;
	border: 2px solid silver;
	background-color: rgba(51, 51, 51, 0.75);
}
