.right {
	justify-content: flex-end;
	justify-self: end;
}

.game-right-panel {
	position: relative;
	display: grid;
	grid-template-rows: 30px 1fr;
	min-width: 450px;
	grid-area: right-panel;
	grid-row: 1 / 4;
}

.game-right-panel-social {
	position: relative;
	height: 100%;
	display: grid;
	grid-template-rows: max-content 1fr;
}

.right-panel-currency {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.right-panel-currency-item:hover {
	transform: scale(1.02);
}

.right-panel-currency-item-platinum > img {
	padding: 4px;
}

.right-panel-currency-item {
	height: 100%;
	padding: 6px;
	transition: transform 0.05s;
	position: relative;
}

.right-panel-currency-item {
	cursor: pointer;
}

.right-panel-currency-item-icon {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.right-panel-currency-item-text {
	width: 100%;
	height: 50%;
	text-align: center;
	/* flex: 1; */
}

.right-panel-content {
	position: relative;
	flex: 1;
	overflow-y: hidden;
	/* 	border: 2px solid rgba(192, 192, 192, 0.3); */
}

.right-panel-container {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.notification-circle {
	position: absolute;
	height: 20px;
	left: 5px;
}
