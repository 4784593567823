.theme-settings {
	background-image: url('/images/background_basic.svg');
}

.settings-wrapper {
	margin: auto;
	max-width: 800px;
}

.settings-container {
	display: grid;
	grid-template-columns: minmax(110px, 0.15fr) 1fr;
	height: 100%;
}

.settings-container.settings-category-search {
	display: initial;
}

.settings-panel {
	position: relative;
	overflow-y: hidden;
}

.settings-header {
	font-size: 32px;
	text-align: center;
	padding-bottom: 4px;
}

.settings-section {
	overflow-y: auto;
	height: 100%;
	padding: 20px;
}

.settings-row .MuiCheckbox-root {
	color: white;
}

.settings-label {
	font-size: 16px;
}

.settings-row {
	display: grid;
	width: 100%;
	grid-template-columns: 1.2fr minmax(150px, 1fr);
	line-height: 20px;
	align-items: center;
	min-height: 39px;
}

.settings-row.settings-row-checkbox {
	grid-template-columns: 3fr 1fr;
}

.settings-row-slider .MuiSlider-markLabel {
	color: white;
}

.settings-slider {
	color: white;
	width: 90% !important;
	margin-right: 10px;
}

.settings-dropbox {
	padding: 4px 10px;
	font-size: 16px;
	text-align: right;
	margin-right: 10px;
}

.settings-email-verified {
	color: green;
}

.settings-email-not-verified {
	color: red;
}

.settings-slider,
.settings-checkbox {
	display: block;
	margin-left: auto !important;
}

.settings-category-6 .settings-row {
	/* grid-template-columns: 1fr 1fr 1fr !important; */
	grid-template-columns: 1fr minmax(250px, 0.6fr) auto;
}

.settings-email-verified {
	grid-column-start: span 3;
}

.settings-title {
	font-size: 24px;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 4px;
	align-items: center;
}

.settings-description {
	font-size: 14px;
	color: rgb(148, 148, 148);
}

.mobile-layout .settings-container {
	display: initial;
}

.mobile-layout .settings-container .shop-categories {
	display: none;
	grid-template-rows: initial;
	height: calc(100% - 20px);
	width: calc(100% - 20px);
	text-align: center;
	font-size: 16px;
	margin: 10px;
}

.mobile-layout .settings-container.settings-category-none .shop-categories {
	display: grid;
}

.mobile-layout .settings-container .shop-category {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.mobile-layout .settings-category-none .settings-panel {
	display: none;
}

.mobile-layout .settings-container .settings-mobile-back-button {
	display: initial;
	position: fixed;
	top: 60px;
	left: 30px;
	z-index: 10;
}

.mobile-layout .settings-container.settings-category-none .settings-mobile-back-button {
	display: none;
}
