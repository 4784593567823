.icon30 {
	height: 30px;
	width: 30px;
	margin: 2px 10px 2px 0;
}

.navbar3 {
	/* background-color: rgba(0, 0, 0, 0.3); */
	/* border: 2px solid rgba(192, 192, 192, 0.3); */
	border-top: none;
	position: relative;
	border-radius: 0 0 40px 40px;
	width: 100%;
	height: 60px;

	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
		url(/images/ui/stone_ui_tile_dark_gray.png);
	/* filter: drop-shadow(0 0 0.45rem black); */
	box-shadow: 0 -10px 10px -10px rgb(0, 0, 0) inset;
	margin-bottom: 5px;
}

.skill-levels-total-combat {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.05)),
		url(/images/ui/stone_ui_tile_dark_gray.png);
	height: 60px;
	position: absolute;
	z-index: 2;
	left: 0;
	right: 0;
	margin: 0 auto;
	border-top: 0;
	border-bottom-left-radius: 50px;
	border-bottom-right-radius: 50px;

	filter: drop-shadow(0 0 0.45rem black);
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.3) inset;
}
