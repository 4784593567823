.enchanting-container {
	height: 100%;
}

.enchanting-tabs {
	display: grid;
	margin-top: -5px;
	z-index: 100;
	justify-content: center;
	grid-template-columns: repeat(4, 25%);
}

.enchanting-tab-icon {
	width: 26px;
	height: 26px;
}

.enchanting-tabs > div {
	text-align: center;
	border: 2px solid rgba(255, 0, 255, 0.2);
	background: linear-gradient(to bottom, rgb(65, 0, 68), rgb(107, 0, 110));
	height: 35px;
	line-height: 30px;
	text-overflow: ellipsis;
	display: inline-block;
	white-space: nowrap;
	/* justify-self: right; */
}

.enchanting-tab-selected {
	background: linear-gradient(to bottom, rgb(42, 0, 44), rgb(77, 0, 80)) !important;
}

.enchanting-tabs > div:first-child {
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	box-shadow: inset -11px 0 8px -10px rgb(123, 0, 128);
	border-right: none;
}

.enchanting-tabs > div:nth-child(2),
.enchanting-tabs > div:nth-child(3) {
	box-shadow: inset -11px 0 8px -10px rgb(123, 0, 128), inset 11px 0 8px -10px rgb(123, 0, 128);
	border-left: none;
	border-right: none;
}

.enchanting-tabs > div:nth-child(4) {
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	box-shadow: inset 11px 0 8px -10px rgb(123, 0, 128);
	border-left: none;
}

.enchanting-tabs > div > img {
	vertical-align: middle;
	margin-bottom: 4px;
	filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.9));
}

.enchanting-tabs > div > span {
	font-size: 16px;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
}

.theme-enchanting {
	overflow-x: hidden;
	background-image: url('/images/Background_triangle_enchanting.svg');
}

.enchanting-title-border {
	width: 80%;
	height: 10px;
	border-bottom: 1px solid white;
	margin-top: -10px;
	margin-left: auto;
	margin-right: auto;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

@media (max-width: 410px) {
	.enchanting-tabs > div > span {
		font-size: 13px;
	}

	.enchanting-tabs > div {
		line-height: 30px;
	}
}

.enchanting-main {
	height: calc(100% - 32px);
	margin-top: 5px;
	display: flex;
	width: 100%;
	gap: 6px;
}

.mobile-layout .enchanting-main {
	flex-direction: column;
	height: unset;
}

.enchanting-main > div {
	background-color: rgba(0, 0, 0, 0.849);
	border: 2px solid rgba(192, 192, 192, 0.2);
	text-align: center;
}

.enchanting-window {
	position: relative;
	min-height: 200px;
	flex-basis: 250px;
	display: table;
	overflow: hidden;
}

.enchanting-window-component-container {
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	display: flex;
	justify-content: space-evenly;
}

.enchanting-scrolls-container,
.enchanting-items-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	/* justify-content: space-evenly; */
	flex-wrap: wrap;
}

.enchanting-items,
.enchanting-scrolls {
	flex: 1;
	width: 100%;
	align-self: center;
	overflow-y: auto;
	overflow-x: hidden;
}

.enchanting-window-item {
	height: 80px;
	width: 80px;
	background-image: url('/images/ui/frame_box.png');
	background-size: cover;
	background-position: center;
	position: relative;
	display: flex;
	align-items: center;
}

.enchanting-window-item {
	border-radius: 10px;
}

.enchanting-window-enchant-container {
	/* height: 100%; */
	/* height: calc(100% - 172px); */
	/* margin-bottom: 85px; */
	/* overflow-y: auto; */
}

.enchanting-window-apply {
	position: absolute;
	width: 100%;
	bottom: 0;
}

.enchanting-window-apply > p {
	min-height: 17px;
	max-height: 50px;
	margin-bottom: 0;
	margin-top: 0;
}

.enchanting-glowing-button {
	animation: glow-enchanting 1600ms ease-out infinite alternate;
}

.enchanting-glowing-button:hover {
	background-color: rgb(64, 0, 70);
}

.high-performance .enchanting-glowing-button {
	animation: none;
}

@keyframes glow-enchanting {
	0% {
		box-shadow: 0 0 0 rgba(151, 1, 156, 0.2), inset 0 0 0 rgba(151, 1, 156, 0.1);
	}
	100% {
		box-shadow: 0 0 8px 12px rgba(151, 1, 156, 0.6), inset 0 0 8px 4px rgba(151, 1, 156, 0.4);
	}
}

@keyframes glow-augmenting {
	0% {
		box-shadow: 0 0 0 rgba(192, 196, 0, 0.2), inset 0 0 0 rgba(192, 196, 0, 0.1);
	}
	100% {
		box-shadow: 0 0 3px 5px rgba(192, 196, 0, 0.6), inset 0 0 1px 2px rgba(192, 196, 0, 0.4);
	}
}

@keyframes glow-augmenting-stop {
	0% {
		box-shadow: 0 0 0 rgba(196, 0, 0, 0.2), inset 0 0 0 rgba(196, 0, 0, 0.1);
	}
	100% {
		box-shadow: 0 0 3px 5px rgba(196, 0, 0, 0.6), inset 0 0 1px 2px rgba(196, 0, 0, 0.4);
	}
}

@keyframes brightness {
	0% {
		filter: brightness(1);
		box-shadow: 0 0 0 rgba(151, 1, 156, 0.2), inset 0 0 0 rgba(151, 1, 156, 0.1), 0 2px 0 #000;
	}
	50% {
		filter: brightness(3);
	}
	75% {
		box-shadow: 0 0 30px 20px rgba(151, 1, 156, 0.6), inset 0 0 10px 5px rgba(151, 1, 156, 0.4), 0 2px 0 #000;
	}
	100% {
		filter: brightness(1);
		box-shadow: 0 0 0 rgba(151, 1, 156, 0.2), inset 0 0 0 rgba(151, 1, 156, 0.1), 0 2px 0 #000;
	}
}

@keyframes augmenting-success {
	0% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgba(192, 196, 0, 0.2), inset 0 0 0 rgba(192, 196, 0, 0.1);
	}
	30% {
		filter: brightness(3);
	}
	40% {
		filter: brightness(3);
		border-color: rgb(182, 179, 0);
		box-shadow: 0 0 12px 8px rgba(192, 196, 0, 0.6), inset 0 0 6px 3px rgba(192, 196, 0, 0.4);
	}
	45% {
		border-color: rgb(182, 179, 0);
		filter: brightness(5);
		box-shadow: 0 0 12px 8px rgba(33, 196, 0, 0.6), inset 0 0 6px 3px rgba(33, 196, 0, 0.4);
	}
	65% {
		filter: brightness(3);
	}
	100% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgba(33, 196, 0, 0.2), inset 0 0 0 rgba(33, 196, 0, 0.1);
	}
}

@keyframes augmenting-failure {
	0% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgba(192, 196, 0, 0.2), inset 0 0 0 rgba(192, 196, 0, 0.1);
	}
	30% {
		filter: brightness(3);
	}
	40% {
		filter: brightness(3);
		border-color: rgb(182, 179, 0);
		box-shadow: 0 0 12px 8px rgba(192, 196, 0, 0.6), inset 0 0 6px 3px rgba(192, 196, 0, 0.4);
	}
	45% {
		border-color: rgb(182, 179, 0);
		filter: brightness(5);
		box-shadow: 0 0 12px 8px rgba(196, 0, 0, 0.6), inset 0 0 6px 3px rgba(196, 0, 0, 0.4);
	}
	65% {
		filter: brightness(3);
	}
	100% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgba(196, 0, 0, 0.2), inset 0 0 0 rgba(196, 0, 0, 0.1);
	}
}

@keyframes augmenting-transform {
	0% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgba(192, 196, 0, 0.2), inset 0 0 0 rgba(192, 196, 0, 0.1), 0 2px 0 #000;
	}
	30% {
		filter: brightness(3);
	}
	40% {
		filter: brightness(3);
		border-color: rgb(182, 179, 0);
		box-shadow: 0 0 12px 8px rgba(192, 196, 0, 0.6), inset 0 0 6px 3px rgba(192, 196, 0, 0.4), 0 2px 0 #000;
	}
	45% {
		border-color: rgb(182, 179, 0);
		filter: brightness(5);
		box-shadow: 0 0 12px 8px rgb(0, 65, 196), inset 0 0 6px 3px rgb(0, 56, 196), 0 2px 0 #000;
	}
	65% {
		filter: brightness(3);
	}
	100% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgb(0, 88, 196), inset 0 0 0 rgb(0, 88, 196), 0 2px 0 #000000;
	}
}

@keyframes augmenting-special {
	0% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgba(192, 196, 0, 0.2), inset 0 0 0 rgba(192, 196, 0, 0.1), 0 2px 0 #000;
	}
	30% {
		filter: brightness(3);
	}
	40% {
		filter: brightness(3);
		border-color: rgb(182, 179, 0);
		box-shadow: 0 0 12px 8px rgba(192, 196, 0, 0.6), inset 0 0 6px 3px rgba(192, 196, 0, 0.4), 0 2px 0 #000;
	}
	45% {
		border-color: rgb(182, 179, 0);
		filter: brightness(5);
		box-shadow: 0 0 12px 8px rgb(59, 0, 196), inset 0 0 6px 3px rgb(75, 0, 196), 0 2px 0 #000;
	}
	65% {
		filter: brightness(3);
	}
	100% {
		filter: brightness(1);
		border-color: rgb(105, 99, 17);
		box-shadow: 0 0 0 rgb(98, 0, 196), inset 0 0 0 rgb(98, 0, 196), 0 2px 0 #000000;
	}
}

/* .enchanting-window-item img {
	filter: drop-shadow( 1px 1px px rgb(255, 238, 0)) !important;
} */

.enchanting-window-item.enchanting-succesful {
	animation: brightness 3s infinite;
}

.high-performance .augmenting-success-animation,
.high-performance .augmenting-failure-animation,
.high-performance .augmenting-transform-animation,
.high-performance .augmenting-special-animation,
.high-performance .enchanting-window-item.enchanting-succesful {
	animation: none;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes move {
	0% {
		transform: translatex(0);
	}
	80% {
		transform: translatex(-100px);
	}
	100% {
		transform: translatex(-100px);
	}
}

.enchanted-text {
	color: #eaf;
	border: none;
}

.enchanted-text-combat,
.enchanted-text-constitution,
.enchanted-text-attack,
.enchanted-text-defense,
.enchanted-text-strength,
.enchanted-text-magic,
.enchanted-text-range {
	color: rgb(255, 123, 123);
}
.enchanted-text-gathering,
.enchanted-text-mining,
.enchanted-text-foraging,
.enchanted-text-fishing {
	color: rgb(117, 255, 117);
}
.enchanted-text-production,
.enchanted-text-farming,
.enchanted-text-enchanting,
.enchanted-text-runecrafting,
.enchanted-text-smithing,
.enchanted-text-cooking,
.enchanted-text-crafting {
	color: rgb(255, 255, 128);
}
.enchanted-text-skilling,
.enchanted-text-total {
	color: rgb(112, 255, 255);
}

.enchanted-text-modern-combat,
.enchanted-text-modern-constitution,
.enchanted-text-modern-attack,
.enchanted-text-modern-defense,
.enchanted-text-modern-strength,
.enchanted-text-modern-magic,
.enchanted-text-modern-range {
	color: rgb(255, 50, 50);
}
.enchanted-text-modern-gathering,
.enchanted-text-modern-mining,
.enchanted-text-modern-foraging,
.enchanted-text-modern-fishing  {
	color: rgb(0, 255, 0);
}
.enchanted-text-modern-production,
.enchanted-text-modern-farming,
.enchanted-text-modern-enchanting,
.enchanted-text-modern-runecrafting,
.enchanted-text-modern-smithing,
.enchanted-text-modern-cooking,
.enchanted-text-modern-crafting {
	color: yellow;
}
.enchanted-text-modern-skilling,
.enchanted-text-modern-total {
	color: rgb(0, 255, 255);
}

.enchanting-window-item .item {
	margin-left: -1px;
	margin-top: -1px;
	height: 80px;
	width: 80px;
}

.enchanting-strength-slider {
	padding-left: 4%;
	padding-right: 4%;
	margin-left: 5%;
	margin-right: 12%;
}

.enchanting-title {
	margin: 5px;
}

.mobile-layout .enchanting-items-container {
	order: 1;
	flex: 1;
}

.mobile-layout .enchanting-scrolls-container {
	order: 2;
	flex: 0.6;
	max-height: 200px;
}

.mobile-layout .enchanting-window {
	order: 3;
	flex-basis: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.mobile-layout .enchanting-window-enchant-container > .enchanting-window-component-container {
	margin-left: 0;
}

.mobile-layout .enchanting-window-enchant-container > div {
	margin-left: -100%;
}

.mobile-layout .enchanting-window-apply {
	position: relative;
	margin-top: 20px;
	grid-column: 1 / -1;
}
