.mobile-layout .play-area-wrapper {
	height: 100%;
	overflow: hidden;
}

.mobile-layout .chat-inner-container {
	border-image: none !important;

	grid-template-columns: 1fr;
	grid-template-rows: 0.2fr 1fr 30px;
	row-gap: 10px;
}

.mobile-layout .chat-inner-container .chat-tabs-list {
	grid-row: unset;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
}

.mobile-layout .play-area-background-theme-chat {
	border-image-source: url(/images/ui/stone-9slice-dark-gray.png) !important;
}

.mobile-layout .play-area-background-theme-inventory {
	border-image-source: url(/images/ui/stone-9slice-dark-gray.png) !important;
}
