html {
	box-sizing: border-box;
}

img {
	vertical-align: middle;
	border-style: none;
}

body,
html,
#root,
.App {
	height: 100%;
	font: 14px 'Exo 2', sans-serif;
	color: white;
	scrollbar-color: #767677 #1e1e1f;
	scrollbar-width: thin;
	overscroll-behavior: none;
}

.text-center {
	text-align: center;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.hidden {
	display: none;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
	background: rgb(71, 71, 71);
	border-radius: 10px;
	border: 1px solid rgb(41, 41, 41);
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

* {
	box-shadow: none;
	-webkit-box-shadow: none;
	/* 	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text; */
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

/* React sometimes puts a shadow iframe overlay, which prevents any interaction
This *could* cause issues in future, so feel free to remove */
body > iframe {
	display: none;
}

*,
*:before,
*:after {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}
