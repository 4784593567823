.game-container {
	cursor: default;
	font: 14px 'Exo 2', sans-serif;
	background-color: black;

	/* background-image: url("/images/background_basic_blurred.png"); */
	-webkit-background-size: cover;
	background-size: cover;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	overflow-y: hidden;

	transition: all 10s ease;
	display: grid;
	grid-template-areas:
		'navbar  navbar'
		'navdrawer .';
	grid-template-rows: 40px minmax(0, 1fr);
	grid-template-columns: [unpinned-start] auto [pinned-start] 1fr [game-end];
}

.game-container::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.6)),
		url('/images/ui/background_midjourney_v1_blurred.png');
	-webkit-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;

	transition: all 100s linear;
}

.centered {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
	white-space: nowrap;
}

.all-items {
	display: grid;
	grid-template-columns: repeat(auto-fill, 64px);
	grid-auto-rows: 64px;
	gap: 3px;
	justify-content: center;
}

.mobile-layout .all-items {
	grid-template-columns: repeat(auto-fill, 50px);
	grid-auto-rows: 50px;
}

.fake-70 {
	width: 67px;
}

.loading-container {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

button.add-queue-button {
	display: none;
}
.action-queue button.add-queue-button {
	display: initial;
}
