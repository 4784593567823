.resource-as-row-container {
	height: 70px;
	border: 1px solid gray;
	background-color: rgba(0, 0, 0, 0.5);
	display: grid;
	grid-template-columns: 60px 2fr 5fr 150px;
	grid-template-rows: 90% 10% 0;
	grid-template-areas: 'image title resources button' 'bar bar bar bar';
	position: relative;
	color: white;
	width: 100%;
	margin-bottom: 10px;
}

.resource-as-row-image {
	grid-area: image;
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	margin: auto;
	vertical-align: middle;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.resource-as-row-title {
	grid-area: title;
	text-align: center;
	vertical-align: middle;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 10px;
	font-size: 18px;
}

.resource-as-row-required-resources {
	grid-area: resources;
	max-height: 100%;
	/* max-width: 35%; */
}

.resource-as-row-property {
	float: right;
	min-height: 25px;
	padding: 3px;
	border: 1px solid gray;
	border-radius: 10px;
	margin-top: 5px;
	margin-bottom: 0;
	margin-right: 2px;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.resource-as-row-button {
	grid-area: button;
	height: 90%;
	width: 100%;
	margin: 0;
	text-align: center !important;
}

.resource-as-row-button button {
	width: 100%;
	height: 100%;
	border-radius: 0;
	box-shadow: none;
	background: linear-gradient(180deg, #485563, #29323c);
	border: 2px solid hsla(0, 0%, 75.3%, 0.2);
	cursor: pointer;
}

.resource-as-row-button button:hover {
	background: linear-gradient(180deg, #0f2027, #203a43, #2c5364) !important;
}

.resource-as-row-button button:disabled,
.resource-as-row-button button[disabled] {
	pointer-events: none;
	background-color: #dfdfdf !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #9f9f9f !important;
	cursor: default;
}

.resource-as-row-locked-cover {
	grid-column-start: image-start; /* only takes up one cell because grid-column-end default value is one */
	grid-column-end: span 4;
	grid-row-start: image-start;
	grid-row-end: span 2;
	align-self: stretch;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 1;
}

.resource-as-row-locked-cover p {
	position: relative;
	text-align: center;
	font-size: 32px;
	margin: 0;
}

.resource-as-row-locked-cover p img {
	vertical-align: middle;
	margin-right: 5px;
}

.resource-as-row-locked-cover p:first-child {
	right: 0;
	font-size: 40px;
	margin-top: 4px;
}

.icon50 {
	height: 50px;
	width: 50px;
}

.mobile-layout .resource-as-row-container {
	grid-template-columns: 40px 1fr 2fr 1fr;
	font-size: 12px;
}

.mobile-layout .resource-as-row-title {
	font-size: 16px;
	margin-left: 5px;
}

.mobile-layout .resource-as-row-button > button {
	font-size: 16px;
}

.action-queue .resource-as-row-button button {
	height: 60%;
}

.action-queue .resource-as-row-button .add-queue-button {
	background: linear-gradient(180deg, #485563, #29323c);
}
