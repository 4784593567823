.idlescape-notification .rnc__notification-item {
	/* background-color: rgba(0, 0, 0, 0.849); */

	/* background-image: url(/images/ui/stone_ui_tile.png); */
}

.rnc__base {
	z-index: 100001;
}

.idlescape-notification .rnc__notification-timer {
	opacity: 0.6;
}

.rnc__notification-item--success,
.rnc__notification-item--info,
.rnc__notification-item--warning,
.rnc__notification-item--danger {
	border-left: none;
	background-color: transparent;
}

.rnc__notification-item--success > .rnc__notification-content {
	border-image-source: url(/images/ui/stone-9slice-green-v3.png);
}

.rnc__notification-item--info > .rnc__notification-content {
	border-image-source: url(/images/ui/stone-9slice-blue-v3.png);
}

.rnc__notification-item--warning > .rnc__notification-content {
	border-image-source: url(/images/ui/stone-9slice-yellow.png);
}

.rnc__notification-item--danger > .rnc__notification-content {
	border-image-source: url(/images/ui/stone-9slice-red.png);
}

.rnc__notification-content {
	border-image-source: url(/images/ui/stone-9slice.png);
	border-image-slice: 100 fill;
	border-image-width: 70px;
	border-image-outset: 0;
	border-image-repeat: round;
	padding: 30px;
}

.idlescape-notification > div >  div:first-child .clear-all-button {
	display: initial;
}
