.crafting-item {
	width: 100%;
	align-items: center;
	display: flex;
	flex-direction: column;
	row-gap: 6px;
}

.crafting-item-row-item {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 0.5;
	height: 100%;
	width: 100%;
}

.crafting-item-name {
	padding: 10px;
	text-align: center;
	font-size: 20px;
}

.crafting-item-icon {
	position: relative;
	width: 150px;
	max-width: 150px;
	height: 150px;
	max-height: 150px;
	border-radius: 10px;
	background-image: url('/images/ui/frame_box.png');
	background-size: cover;
	background-position: center;
	margin: auto;
	object-fit: contain;
}

.crafting-item-description {
	flex: 1;
	padding-left: 6px;
	padding-right: 6px;
	overflow-y: auto;
	font-size: 16px;
}

.crafting-item-exp {
	padding: 0;
	text-align: center;
	font-size: 16px;
}

.crafting-item-exp.red {
	color: red;
}

.crafting-item-icon > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.crafting-item-row {
	display: flex;
	flex-direction: column;
	row-gap: 6px;
	position: relative;
	flex: 1;
	height: 100%;
	width: 100%;
	padding: 5px;
	overflow-y: auto;
}

.crafting-options-requirements-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	overflow-x: hidden;
}

.crafting-options-requirements {
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: 6px;
	overflow-y: auto;
	background: rgba(36, 36, 36, 0.671);
	border: 2px solid rgba(255, 255, 255, 0.452);
	padding: 6px;
	border-radius: 10px;
}

.crafting-item-resource {
	padding: 0 10px 0 10px;
	font-size: 20px;
	background: rgba(90, 90, 90, 0.74);
	border-radius: 10px;
}

.crafting-item-resource.red {
	color: rgb(255, 79, 79);
	background: rgb(46, 46, 46) !important;
}

.crafting-item-resource-icon {
	margin: 2px;
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.crafting-options {
	padding: 6px;
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: 6px;
}

.crafting-option-row {
	display: flex;
	flex: 0;
	column-gap: 6px;
}

.craft-animation {
	animation: crafting 1s ease;
}

.high-performance .craft-animation {
	animation: none;
}

@keyframes crafting {
	0% {
		border-color: unset;
		box-shadow: none;
	}
	50% {
		border-color: rgb(0, 182, 0);
		box-shadow: 0 0 5px 3px rgba(0, 196, 0, 0.6), inset 0 0 2px 5px rgba(0, 196, 0, 0.4);
	}
	100% {
		border-color: unset;
		box-shadow: none;
	}
}

@media (max-width: 1600px) {
	.crafting-item-icon {
		width: 125px;
		max-width: 125px;
		height: 125px;
		max-height: 125px;
	}

	.crafting-item-resource {
		font-size: 18px;
	}
}

@media (max-width: 1200px) {
	.crafting-item-icon {
		width: 100px;
		max-width: 100px;
		height: 100px;
		max-height: 100px;
	}

	.crafting-item-resource {
		font-size: 16px;
	}
}

@media (max-width: 700px) {
	.crafting-item-icon {
		width: 75px;
		max-width: 75px;
		height: 75px;
		max-height: 75px;
	}

	.crafting-item-resource {
		font-size: 12px;
	}
	.crafting-item-exp {
		font-size: 14px;
	}
	.crafting-item {
		flex-basis: 125px;
	}
}
