.talent-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.talent-container > div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.talent {
	max-width: 300px;
	height: min-content;
}

.talent-root-grid {
	display: flex;
	flex-wrap: wrap;
}

.talent-grid {
	display: flex;
	flex-wrap: wrap;
}
