.runecrafting-container {
	height: 100%;
}

.runecrafting-tabs {
	display: grid;
	width: 100%;
	margin-top: -5px;
	z-index: 100;
	justify-content: center;
	grid-template-columns: repeat(3, 33%);
	white-space: nowrap;
}

.runecrafting-tab-icon {
	width: 26px;
	height: 26px;
}

.runecrafting-tabs > div {
	text-align: center;
	border: 2px solid rgba(0, 0, 255, 0.2);
	background: linear-gradient(to bottom, rgb(0, 65, 85), rgb(0, 98, 128));
	height: 35px;
	line-height: 30px;
	text-overflow: ellipsis;
	display: inline-block;
	/* justify-self: right; */
}

.runecrafting-tab-selected {
	background: linear-gradient(to bottom, rgb(0, 45, 65), rgb(0, 75, 98)) !important;
}

.runecrafting-tabs > div:first-child {
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	box-shadow: inset -11px 0 8px -10px rgb(0, 98, 128);
	border-right: none;
}

.runecrafting-tabs > div:nth-child(3) {
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	box-shadow: inset 11px 0 8px -10px rgb(0, 98, 128);
	border-left: none;
}

.runecrafting-tabs > div > img {
	vertical-align: middle;
	margin-bottom: 4px;
	filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.9));
}

.runecrafting-tabs > div > span {
	font-size: 16px;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
}

.runecrafting-main {
	display: grid;
	margin-left: auto;
	margin-right: auto;
	/* grid-template-rows: 50px; */
	grid-template-columns: 3fr 180px;
	width: 80%;
}

.crafting-grid {
	padding-top: 3px;
}

.essence-list img {
	float: left;
	height: 60px;
	width: 60px;
	padding-top: 6px;
	padding-left: 6px;
	margin-right: 8px;
}

.essence-list {
	padding-top: 3px;
	display: grid;
	grid-template-columns: 100%;
	min-width: 180px;
	max-width: 180px;
	justify-content: space-evenly;
}

.runecrafting-essence-display {
	width: 100%;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: rgba(0, 0, 0, 0.658);
	margin-bottom: 10px;
	border: 1px solid rgba(238, 255, 0, 0.39);
	border-left: none;
}

.runecrafting-essence-counter {
	position: absolute;
	padding-top: 30px;
	height: 100%;
	font-size: 20px;
}

.essence-list div {
	position: relative;
}

.theme-runecrafting {
	background-image: url('/images/Background_triangle_runecrafting.svg');
}

.runecrafting-container .resource-as-row-button > button {
	background: #646464; /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom left, #969696, #474747); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to bottom left,
		#969696,
		#474747
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	border: 1px solid #727266;
	/* color: rgb(44, 44, 44); */
	color: white;
	font-size: 18px;
	text-shadow: 0 0 2px gold;
	border-bottom-left-radius: 20px;
	margin-left: 2px;
	/* box-shadow: inset 0 0 10px 2px #a5a01e; */
}
.runecrafting-container .resource-as-row-button > button:hover {
	background: #cac531; /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom left, #b8b8b8, #696969) !important; /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to bottom left,
		#b8b8b8,
		#696969
	) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	border: 1px solid #616157;
	/* color: rgb(44, 44, 44); */
	color: white;
	font-size: 20px;
	text-shadow: 0 0 5px gold !important;
	/* box-shadow: inset 0 0 10px 2px #a5a01e; */
}

.runecrafting-info {
	padding: 10px;
	border: 1px solid rgba(238, 255, 0, 0.39);
	background-color: rgba(0, 0, 0, 0.658);
	margin: 10px auto;
	line-height: 20px;
	border-radius: 5px;
}

.runecrafting-tooltip {
	font-size: 16px;
}

.runecrafting-text {
	color: rgb(182, 194, 16);
}

.runecrafting-container .resource-as-row-property {
	border: 1px solid rgba(238, 255, 0, 0.39);
}

.has-talisman {
	-webkit-filter: drop-shadow(0 0 5px #5ebeff) drop-shadow(0 0 5px rgba(94, 191, 255, 0.5));
	filter: drop-shadow(0 0 5px #5ebeff) drop-shadow(0 0 5px rgba(94, 191, 255, 0.5));
}

.no-talisman {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
}

.mobile-layout .runecrafting-main {
	grid-template-columns: 1fr 70px;
	width: 100%;
}

.mobile-layout .essence-list {
	min-width: 50px;
}

.mobile-layout .runecrafting-essence-counter {
	/* text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000; */
	font-size: 14px;
	text-align: center;
	left: 0;
	right: 0;
	padding: 0 3px;
	margin: auto;
	width: fit-content;
	height: initial;
	bottom: 5px;
	border-radius: 5px;
	background-color: #0008;
}

/*
*	Affixes Rune sprites
*/
.affix_runes {
	border-radius: 10px;
	/* Images are 640px x 832px. Sizes are [dimension]/160px */
	background-size: 400% 520%;
	background-repeat: no-repeat;
}
.affix_runes_sprite_1 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_1.jpg');
}
.affix_runes_sprite_2 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_2.jpg');
}
.affix_runes_sprite_3 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_3.jpg');
}
.affix_runes_sprite_4 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_4.jpg');
}
.affix_runes_sprite_5 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_5.jpg');
}
.affix_runes_sprite_6 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_6.jpg');
}
.affix_runes_sprite_7 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_7.jpg');
}
.affix_runes_sprite_8 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_8.jpg');
}
.affix_runes_sprite_9 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_9.jpg');
}
.affix_runes_sprite_10 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_10.jpg');
}
.affix_runes_sprite_11 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_11.jpg');
}
.affix_runes_sprite_12 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_12.jpg');
}
.affix_runes_sprite_13 {
	background-image: url('/images/runecrafting/affix_runes/sprite_runes_13.jpg');
}

.affix_rune_101 {
	background-position: 1.25% 2.98%;
}
.affix_rune_102 {
	background-position: 33.33% 2.98%;
}
.affix_rune_103 {
	background-position: 65.21% 2.98%;
}
.affix_rune_104 {
	background-position: 98.54% 2.98%;
}
.affix_rune_105 {
	background-position: 1.25% 26.79%;
}
.affix_rune_106 {
	background-position: 33.33% 26.79%;
}
.affix_rune_107 {
	background-position: 65.21% 26.79%;
}
.affix_rune_108 {
	background-position: 98.54% 26.79%;
}
.affix_rune_109 {
	background-position: 1.25% 50.6%;
}
.affix_rune_110 {
	background-position: 33.33% 50.6%;
}
.affix_rune_111 {
	background-position: 65.21% 50.6%;
}
.affix_rune_112 {
	background-position: 98.54% 50.6%;
}
.affix_rune_113 {
	background-position: 1.25% 74.26%;
}
.affix_rune_114 {
	background-position: 33.33% 74.11%;
}
.affix_rune_115 {
	background-position: 65.21% 74.26%;
}
.affix_rune_116 {
	background-position: 98.54% 74.26%;
}
.affix_rune_117 {
	background-position: 1.25% 97.47%;
}
.affix_rune_118 {
	background-position: 33.33% 97.47%;
}
.affix_rune_119 {
	background-position: 65.21% 97.47%;
}
.affix_rune_120 {
	background-position: 98.54% 97.47%;
}

.affix_rune_201 {
	background-position: 0.83% 2.98%;
}
.affix_rune_202 {
	background-position: 32.92% 2.98%;
}
.affix_rune_203 {
	background-position: 65.21% 2.98%;
}
.affix_rune_204 {
	background-position: 98.96% 2.98%;
}
.affix_rune_205 {
	background-position: 0.83% 26.49%;
}
.affix_rune_206 {
	background-position: 32.92% 26.34%;
}
.affix_rune_207 {
	background-position: 65.21% 26.49%;
}
.affix_rune_208 {
	background-position: 98.96% 26.49%;
}
.affix_rune_209 {
	background-position: 0.83% 49.85%;
}
.affix_rune_210 {
	background-position: 32.92% 49.85%;
}
.affix_rune_211 {
	background-position: 65.42% 49.85%;
}
.affix_rune_212 {
	background-position: 98.96% 49.85%;
}
.affix_rune_213 {
	background-position: 0.42% 73.96%;
}
.affix_rune_214 {
	background-position: 32.92% 73.96%;
}
.affix_rune_215 {
	background-position: 65.21% 73.96%;
}
.affix_rune_216 {
	background-position: 98.96% 73.96%;
}
.affix_rune_217 {
	background-position: 0.83% 97.47%;
}
.affix_rune_218 {
	background-position: 32.92% 97.47%;
}
.affix_rune_219 {
	background-position: 65.42% 97.62%;
}
.affix_rune_220 {
	background-position: 98.96% 97.47%;
}

.affix_rune_301 {
	background-position: 1.04% 2.53%;
}
.affix_rune_302 {
	background-position: 33.33% 2.38%;
}
.affix_rune_303 {
	background-position: 65.21% 2.53%;
}
.affix_rune_304 {
	background-position: 98.54% 2.53%;
}
.affix_rune_305 {
	background-position: 1.04% 26.49%;
}
.affix_rune_306 {
	background-position: 33.33% 26.49%;
}
.affix_rune_307 {
	background-position: 65.62% 26.49%;
}
.affix_rune_308 {
	background-position: 98.54% 26.49%;
}
.affix_rune_309 {
	background-position: 1.04% 50.6%;
}
.affix_rune_310 {
	background-position: 33.33% 50.6%;
}
.affix_rune_311 {
	background-position: 65.21% 50.6%;
}
.affix_rune_312 {
	background-position: 98.75% 50.6%;
}
.affix_rune_313 {
	background-position: 1.04% 74.26%;
}
.affix_rune_314 {
	background-position: 33.33% 74.11%;
}
.affix_rune_315 {
	background-position: 65.21% 74.26%;
}
.affix_rune_316 {
	background-position: 98.54% 74.26%;
}
.affix_rune_317 {
	background-position: 0.83% 97.77%;
}
.affix_rune_318 {
	background-position: 32.5% 97.77%;
}
.affix_rune_319 {
	background-position: 65.21% 98.51%;
}
.affix_rune_320 {
	background-position: 98.54% 97.77%;
}

.affix_rune_401 {
	background-position: 0.83% 2.98%;
}
.affix_rune_402 {
	background-position: 33.33% 2.98%;
}
.affix_rune_403 {
	background-position: 65.21% 2.98%;
}
.affix_rune_404 {
	background-position: 98.54% 2.98%;
}
.affix_rune_405 {
	background-position: 0.83% 26.34%;
}
.affix_rune_406 {
	background-position: 33.33% 26.34%;
}
.affix_rune_407 {
	background-position: 65.42% 26.34%;
}
.affix_rune_408 {
	background-position: 98.54% 26.34%;
}
.affix_rune_409 {
	background-position: 0.83% 50.6%;
}
.affix_rune_410 {
	background-position: 33.33% 50.6%;
}
.affix_rune_411 {
	background-position: 65.42% 50.6%;
}
.affix_rune_412 {
	background-position: 98.54% 50.6%;
}
.affix_rune_413 {
	background-position: 0.83% 74.26%;
}
.affix_rune_414 {
	background-position: 33.33% 74.11%;
}
.affix_rune_415 {
	background-position: 65.42% 74.26%;
}
.affix_rune_416 {
	background-position: 98.54% 74.26%;
}
.affix_rune_417 {
	background-position: 0.83% 97.47%;
}
.affix_rune_418 {
	background-position: 33.12% 97.47%;
}
.affix_rune_419 {
	background-position: 65.83% 97.47%;
}
.affix_rune_420 {
	background-position: 98.54% 97.47%;
}

.affix_rune_501 {
	background-position: 0.83% 2.68%;
}
.affix_rune_502 {
	background-position: 32.92% 2.68%;
}
.affix_rune_503 {
	background-position: 65.21% 2.68%;
}
.affix_rune_504 {
	background-position: 98.54% 2.68%;
}
.affix_rune_505 {
	background-position: 0.83% 26.79%;
}
.affix_rune_506 {
	background-position: 32.92% 26.79%;
}
.affix_rune_507 {
	background-position: 65.21% 26.79%;
}
.affix_rune_508 {
	background-position: 98.54% 26.79%;
}
.affix_rune_509 {
	background-position: 0.62% 50.6%;
}
.affix_rune_510 {
	background-position: 32.92% 50.6%;
}
.affix_rune_511 {
	background-position: 65.21% 50.6%;
}
.affix_rune_512 {
	background-position: 98.54% 50.6%;
}
.affix_rune_513 {
	background-position: 0.62% 74.26%;
}
.affix_rune_514 {
	background-position: 32.92% 74.11%;
}
.affix_rune_515 {
	background-position: 65.21% 74.26%;
}
.affix_rune_516 {
	background-position: 98.54% 74.26%;
}
.affix_rune_517 {
	background-position: 0.62% 97.62%;
}
.affix_rune_518 {
	background-position: 32.71% 97.62%;
}
.affix_rune_519 {
	background-position: 65.42% 97.62%;
}
.affix_rune_520 {
	background-position: 98.96% 97.62%;
}

.affix_rune_601 {
	background-position: 1.25% 2.98%;
}
.affix_rune_602 {
	background-position: 33.33% 3.13%;
}
.affix_rune_603 {
	background-position: 65.21% 3.13%;
}
.affix_rune_604 {
	background-position: 98.54% 2.98%;
}
.affix_rune_605 {
	background-position: 1.25% 26.79%;
}
.affix_rune_606 {
	background-position: 33.33% 26.79%;
}
.affix_rune_607 {
	background-position: 65.21% 26.79%;
}
.affix_rune_608 {
	background-position: 98.54% 26.79%;
}
.affix_rune_609 {
	background-position: 0.83% 50.6%;
}
.affix_rune_610 {
	background-position: 33.33% 50.6%;
}
.affix_rune_611 {
	background-position: 65.63% 50.6%;
}
.affix_rune_612 {
	background-position: 98.54% 50.6%;
}
.affix_rune_613 {
	background-position: 1.25% 74.26%;
}
.affix_rune_614 {
	background-position: 33.33% 74.11%;
}
.affix_rune_615 {
	background-position: 65.63% 74.26%;
}
.affix_rune_616 {
	background-position: 98.33% 74.26%;
}
.affix_rune_617 {
	background-position: 1.25% 97.62%;
}
.affix_rune_618 {
	background-position: 33.33% 97.62%;
}
.affix_rune_619 {
	background-position: 65.42% 97.77%;
}
.affix_rune_620 {
	background-position: 98.54% 97.62%;
}

.affix_rune_701 {
	background-position: 0.83% 2.68%;
}
.affix_rune_702 {
	background-position: 33.13% 2.98%;
}
.affix_rune_703 {
	background-position: 65.21% 2.98%;
}
.affix_rune_704 {
	background-position: 98.33% 2.98%;
}
.affix_rune_705 {
	background-position: 0.63% 26.49%;
}
.affix_rune_706 {
	background-position: 32.92% 26.49%;
}
.affix_rune_707 {
	background-position: 65.21% 26.49%;
}
.affix_rune_708 {
	background-position: 98.54% 26.49%;
}
.affix_rune_709 {
	background-position: 0.83% 50.15%;
}
.affix_rune_710 {
	background-position: 33.33% 50.15%;
}
.affix_rune_711 {
	background-position: 65.42% 50.15%;
}
.affix_rune_712 {
	background-position: 98.33% 50.15%;
}
.affix_rune_713 {
	background-position: 0.63% 73.96%;
}
.affix_rune_714 {
	background-position: 32.92% 73.96%;
}
.affix_rune_715 {
	background-position: 65.42% 73.96%;
}
.affix_rune_716 {
	background-position: 98.33% 73.96%;
}
.affix_rune_717 {
	background-position: 0.63% 97.77%;
}
.affix_rune_718 {
	background-position: 32.92% 97.77%;
}
.affix_rune_719 {
	background-position: 65.42% 97.77%;
}
.affix_rune_720 {
	background-position: 98.33% 97.77%;
}

.affix_rune_801 {
	background-position: 0.42% 2.98%;
}
.affix_rune_802 {
	background-position: 32.71% 2.98%;
}
.affix_rune_803 {
	background-position: 65% 3.13%;
}
.affix_rune_804 {
	background-position: 98.54% 3.27%;
}
.affix_rune_805 {
	background-position: 0.63% 26.64%;
}
.affix_rune_806 {
	background-position: 32.92% 26.64%;
}
.affix_rune_807 {
	background-position: 65.21% 26.64%;
}
.affix_rune_808 {
	background-position: 98.54% 26.64%;
}
.affix_rune_809 {
	background-position: 0.63% 50.15%;
}
.affix_rune_810 {
	background-position: 32.71% 50.15%;
}
.affix_rune_811 {
	background-position: 65.21% 50.15%;
}
.affix_rune_812 {
	background-position: 98.33% 50.15%;
}
.affix_rune_813 {
	background-position: 0.63% 73.81%;
}
.affix_rune_814 {
	background-position: 32.92% 73.96%;
}
.affix_rune_815 {
	background-position: 65.21% 73.81%;
}
.affix_rune_816 {
	background-position: 98.33% 73.96%;
}
.affix_rune_817 {
	background-position: 0.63% 97.62%;
}
.affix_rune_818 {
	background-position: 32.92% 97.62%;
}
.affix_rune_819 {
	background-position: 65.21% 97.62%;
}
.affix_rune_820 {
	background-position: 98.33% 97.62%;
}

.affix_rune_901 {
	background-position: 0.63% 2.53%;
}
.affix_rune_902 {
	background-position: 33.33% 2.53%;
}
.affix_rune_903 {
	background-position: 65.42% 2.53%;
}
.affix_rune_904 {
	background-position: 98.33% 2.53%;
}
.affix_rune_905 {
	background-position: 0.83% 26.49%;
}
.affix_rune_906 {
	background-position: 33.33% 26.49%;
}
.affix_rune_907 {
	background-position: 65.42% 26.49%;
}
.affix_rune_908 {
	background-position: 98.33% 26.49%;
}
.affix_rune_909 {
	background-position: 0.63% 50.15%;
}
.affix_rune_910 {
	background-position: 33.33% 50.15%;
}
.affix_rune_911 {
	background-position: 65.42% 50.15%;
}
.affix_rune_912 {
	background-position: 98.33% 50.15%;
}
.affix_rune_913 {
	background-position: 0.63% 73.81%;
}
.affix_rune_914 {
	background-position: 33.33% 73.96%;
}
.affix_rune_915 {
	background-position: 65.42% 73.81%;
}
.affix_rune_916 {
	background-position: 98.33% 73.81%;
}
.affix_rune_917 {
	background-position: 0.63% 97.62%;
}
.affix_rune_918 {
	background-position: 33.33% 97.62%;
}
.affix_rune_919 {
	background-position: 65.42% 97.62%;
}
.affix_rune_920 {
	background-position: 98.33% 97.62%;
}

.affix_rune_1001 {
	background-position: 0.83% 2.53%;
}
.affix_rune_1002 {
	background-position: 32.71% 2.68%;
}
.affix_rune_1003 {
	background-position: 65% 2.68%;
}
.affix_rune_1004 {
	background-position: 97.71% 2.68%;
}
.affix_rune_1005 {
	background-position: 0.63% 25.89%;
}
.affix_rune_1006 {
	background-position: 32.71% 25.6%;
}
.affix_rune_1007 {
	background-position: 64.58% 25.89%;
}
.affix_rune_1008 {
	background-position: 97.71% 26.19%;
}
.affix_rune_1009 {
	background-position: 0.63% 49.4%;
}
.affix_rune_1010 {
	background-position: 32.71% 49.55%;
}
.affix_rune_1011 {
	background-position: 64.79% 49.11%;
}
.affix_rune_1012 {
	background-position: 97.71% 49.55%;
}
.affix_rune_1013 {
	background-position: 0.63% 73.96%;
}
.affix_rune_1014 {
	background-position: 32.71% 73.51%;
}
.affix_rune_1015 {
	background-position: 64.79% 73.66%;
}
.affix_rune_1016 {
	background-position: 97.71% 73.81%;
}
.affix_rune_1017 {
	background-position: 0.83% 97.47%;
}
.affix_rune_1018 {
	background-position: 32.71% 97.32%;
}
.affix_rune_1019 {
	background-position: 65.21% 97.32%;
}
.affix_rune_1020 {
	background-position: 97.71% 97.47%;
}

.affix_rune_1101 {
	background-position: 0.42% 3.27%;
}
.affix_rune_1102 {
	background-position: 32.92% 3.27%;
}
.affix_rune_1103 {
	background-position: 65.42% 3.27%;
}
.affix_rune_1104 {
	background-position: 98.54% 3.13%;
}
.affix_rune_1105 {
	background-position: 0.63% 26.79%;
}
.affix_rune_1106 {
	background-position: 32.92% 26.79%;
}
.affix_rune_1107 {
	background-position: 65.83% 26.79%;
}
.affix_rune_1108 {
	background-position: 98.75% 26.79%;
}
.affix_rune_1109 {
	background-position: 0.42% 50.15%;
}
.affix_rune_1110 {
	background-position: 32.92% 50.15%;
}
.affix_rune_1111 {
	background-position: 65.42% 50.15%;
}
.affix_rune_1112 {
	background-position: 98.75% 50.15%;
}
.affix_rune_1113 {
	background-position: 0.42% 73.96%;
}
.affix_rune_1114 {
	background-position: 32.92% 73.81%;
}
.affix_rune_1115 {
	background-position: 65.42% 73.96%;
}
.affix_rune_1116 {
	background-position: 98.75% 73.96%;
}
.affix_rune_1117 {
	background-position: 0.21% 97.32%;
}
.affix_rune_1118 {
	background-position: 32.92% 97.32%;
}
.affix_rune_1119 {
	background-position: 65.42% 97.32%;
}
.affix_rune_1120 {
	background-position: 98.75% 97.32%;
}

.affix_rune_1201 {
	background-position: 0.83% 2.53%;
}
.affix_rune_1202 {
	background-position: 32.92% 2.53%;
}
.affix_rune_1203 {
	background-position: 65% 2.98%;
}
.affix_rune_1204 {
	background-position: 98.13% 2.68%;
}
.affix_rune_1205 {
	background-position: 0.63% 26.49%;
}
.affix_rune_1206 {
	background-position: 32.71% 26.79%;
}
.affix_rune_1207 {
	background-position: 65.21% 26.49%;
}
.affix_rune_1208 {
	background-position: 98.13% 26.79%;
}
.affix_rune_1209 {
	background-position: 0.83% 50%;
}
.affix_rune_1210 {
	background-position: 32.92% 50%;
}
.affix_rune_1211 {
	background-position: 65.42% 50%;
}
.affix_rune_1212 {
	background-position: 98.13% 50%;
}
.affix_rune_1213 {
	background-position: 0.83% 73.96%;
}
.affix_rune_1214 {
	background-position: 33.13% 73.81%;
}
.affix_rune_1215 {
	background-position: 65.42% 73.81%;
}
.affix_rune_1216 {
	background-position: 97.92% 73.81%;
}
.affix_rune_1217 {
	background-position: 0.83% 97.77%;
}
.affix_rune_1218 {
	background-position: 32.5% 97.77%;
}
.affix_rune_1219 {
	background-position: 65.42% 97.77%;
}
.affix_rune_1220 {
	background-position: 98.33% 97.77%;
}

.affix_rune_1301 {
	background-position: 0.63% 3.13%;
}
.affix_rune_1302 {
	background-position: 32.92% 3.13%;
}
.affix_rune_1303 {
	background-position: 65.21% 3.13%;
}
.affix_rune_1304 {
	background-position: 98.54% 3.13%;
}
.affix_rune_1305 {
	background-position: 0.63% 26.49%;
}
.affix_rune_1306 {
	background-position: 32.92% 26.49%;
}
.affix_rune_1307 {
	background-position: 65.21% 26.7%;
}
.affix_rune_1308 {
	background-position: 98.54% 26.79%;
}
.affix_rune_1309 {
	background-position: 0.63% 50.3%;
}
.affix_rune_1310 {
	background-position: 32.92% 50.3%;
}
.affix_rune_1311 {
	background-position: 65.21% 50.3%;
}
.affix_rune_1312 {
	background-position: 98.54% 50.3%;
}
.affix_rune_1313 {
	background-position: 0.63% 73.96%;
}
.affix_rune_1314 {
	background-position: 32.92% 73.96%;
}
.affix_rune_1315 {
	background-position: 65.42% 73.96%;
}
.affix_rune_1316 {
	background-position: 98.54% 73.96%;
}
.affix_rune_1317 {
	background-position: 0.63% 97.32%;
}
.affix_rune_1318 {
	background-position: 32.92% 97.32%;
}
.affix_rune_1319 {
	background-position: 65.42% 97.47%;
}
.affix_rune_1320 {
	background-position: 98.75% 97.32%;
}
