.theme-quests {
	background-image: url(/images/idlescape_game_background_image_blurred_forest.jpg);
}

.quest-container img {
	object-fit: contain;
}

.quest-type {
	background-color: rgba(0, 0, 0, 0.459);
	box-shadow: 0 5px 4px rgb(0 0 0 / 30%);
	height: 40px;
	padding-top: 10px;
	text-align: center;

	border-image-source: url(/images/ui/stone-9slice.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 100px !important;
	border-image-outset: 0 !important;
	/* padding: 50px !important; */
	border-image-repeat: round !important;
	cursor: pointer;
}

.quest-step,
.quest-list .quest-step {
	min-height: 40px;
	border-image-source: url(/images/ui/stone-9slice.png);
	border-image-slice: 100 fill;
	border-image-width: 40px;
	border-image-outset: 0;
	border-image-repeat: round;
	cursor: pointer;
}

.quest-step.quest-header,
.quest-step.quest-progress-bar {
	border-image-source: url(/images/ui/stone-9slice-dark-gray.png);
}

.quest-step.quest-listing-complete,
.quest-step.quest-header.quest-complete {
	color: yellow;
	border-image-source: url(/images/ui/stone-9slice-yellow-v2.png);
}

.quest-step.quest-listing-in-progress {
	color: rgb(0, 199, 0);
	border-image-source: url(/images/ui/stone-9slice-green-v3.png);
}

.quest-step.quest-listing-in-progress-2 {
	color: rgb(101, 255, 101);
	border-image-source: url(/images/ui/stone-9slice-green-v3.png);
}

.quest-step.quest-listing-requirement-unmet {
	color: rgb(255, 45, 45);
	border-image-source: url(/images/ui/stone-9slice-red.png);
}

.quest-step.quest-listing-completed {
	color: grey;
	border-image-source: url(/images/ui/stone-9slice-dark-gray.png);
}

.quest-step.quest-listing-highlight {
	filter: brightness(1.5);
}

.mobile-quest-accordion-item > div {
	height: 100% !important;
}

.quest-settings > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20%;
}

.quest-not-in-progress {
	color: rgb(255, 25, 25);
	text-align: center;
	font-size: 1.33em;
	font-weight: bold;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.achievement-highlight:hover {
	transition: transform 0.4s;
	filter: brightness(1.05);
	transform: scale(1.1);
}

.achievement-highlight:hover > .share-achievement-button {
	display: block;
}

.achievement-highlight.quest-listing-requirement-unmet > .share-achievement-button {
	display: none;
}

.quest-category-title {
	background-color: rgba(0, 0, 0, 0.459);
	box-shadow: 0 5px 4px rgba(255, 255, 255, 0.3);
	height: 40px;
	padding-top: 10px;
	text-align: center;
}
