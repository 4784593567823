.shop-container {
	display: grid;
	grid-template-columns: 100px auto;
	grid-template-rows: auto;
	row-gap: 5px;
	column-gap: 5px;
	text-align: center;
	height: 100%;
}

.shop-header {
	grid-column-start: span 2;
}

.shop-content {
	z-index: 1;
	/* background-color: rgba(0, 0, 0, 0.8);
	border: 1px solid rgba(255, 217, 0, 0.144); */
}

.shop-container > div {
	height: 100%;
	width: 100%;
}

.shop-categories {
	margin-top: 20px;
	display: grid;
	row-gap: 5px;
	text-align: left;

	line-height: 28px;
	box-sizing: border-box;
	grid-template-rows: repeat(auto-fill, 30px);
}

.shop-category {
	background-color: rgba(0, 0, 0, 0.8);
	cursor: pointer;
	padding-left: 10px;
	width: 150%;
	position: relative;
	/* border: 1px solid rgba(255, 217, 0, 0.144); */
}

.shop-category[data-active='true'] {
	/* background-color: rgba(0, 136, 199, 0.459);
	border: 1px solid rgba(0, 0, 255, 0.623); */
}

.shop-item-list {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
	row-gap: 10px;
	column-gap: 10px;
	padding: 10px;
}

.shop-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	max-height: 200px;
	padding: 10px;
	padding-bottom: 50px !important;
	cursor: pointer;
	transition: scale 0.2s;
	/* row-gap: 10px; */
}

.shop-item:hover {
	scale: 1.05;
}

.shop-item-title {
	font-size: 16px;
	text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgb(54 188 250 / 80%), 0 0 40px rgb(54 188 250 / 80%),
		0 0 50px rgb(54 188 250 / 80%), 0 0 60px rgb(54 188 250 / 80%), 0 0 70px #e60073;
}

.shop-item-image-container img {
	object-fit: contain;
	height: 150px;
	width: 100%;
	padding: 10px;
}

.carousel-root .slider {
	background-color: unset;
}

.carousel-root .carousel {
	height: unset;
}
