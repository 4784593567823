.me-message {
	font-style: italic;
}

.shrine-donation-message {
	color: #04b501;
	text-shadow: 0 0 1px #deff06;
}

.message-platinum-global-buff {
	color: lightblue;
	text-shadow: 0 0 1px #78eef2, 0 0 5px #78eef2;
}

.message-hitBySnowball {
	color: #cfe3ff;
	text-shadow: 0 0 4px #78aefa;
}

.message-hitBySnowball-evil {
	background: linear-gradient(90deg, #ff7ea7, #d9b2fb, #d1c1fb, #d5cdff, #ceeff9);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	font-weight: bold;
}

.message-levelUp99 {
	color: rgb(0, 160, 253);
}

.message-levelUpMax {
	color: rgb(255, 225, 57);
	text-shadow: 0 0 4px rgb(255 4 4);
}

.message-success-augment {
	color: rgb(255, 225, 57);
	text-shadow: 0 0 3px rgb(250, 223, 74);
}

.message-failed-augment {
	color: red;
	text-shadow: 0 0 3px red;
}

.message-rare-loot {
	color: gold;
}

.message-monster-ability {
	color: red;
}

.message-of-the-day {
	color: rgb(132, 167, 229);
	font-weight: bold;
}

.message-easter-code-found,
.message-achievement-unlocked,
.message-achievement-step-complete {
	background: linear-gradient(to right, #f9ceee, #e0cdff, #c1f0fb, #dcf9a8, #ffebaf);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.message-donation-announcement {
	color: rgb(4, 181, 1);
	text-shadow: 0 0 1px rgb(222, 255, 6);
}

.message-buff-madness {
	color: rgb(234, 12, 12);
	text-shadow: 0 0 4px rgb(185, 25, 25);
}
