.chat-chest-icon {
	width: 0.86em;
	height: 0.86em;
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.9));
	margin-top: -2px;
}

.chest-tooltip {
	pointer-events: none;
	z-index: 1000;
	font-size: small;
	width: 265px;
	height: auto;
	overflow: auto;
	/* background: #191919;
    border: 2px solid white; */
	border-radius: 6px;
	padding-top: 5px;
	padding-bottom: 5px;
	position: relative;
	opacity: 0.95;
}

.chest-tooltip-title-container {
	padding-left: 10px;
	margin-bottom: 4px;
	font-size: small;
	border-bottom: 1px solid gray;
}

.chest-tooltip-loot-container {
	padding-top: 5px;
	display: grid;
	gap: 0.3rem;
	justify-content: center;
	grid-template-columns: repeat(auto-fill, 45px);
}

.small-item {
	width: 45px;
	height: 45px;
	float: left;
	position: relative;
	color: white;
	font-size: 15px;
	border-radius: 8px !important;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	background-size: cover;
}
