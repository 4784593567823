.cooking-container {
	height: 100%;
}

.cooking-tabs {
	display: grid;
	margin-top: -5px;
	z-index: 100;
	justify-content: center;
	grid-template-columns: repeat(3, 33.33%);
	text-align: center;
	font-size: 16px;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
}

.cooking-tabs > div {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid rgba(255, 180, 0, 0.2);
	background: linear-gradient(to bottom, rgb(68, 50, 0), rgb(110, 70, 0));
	height: 35px;
}

.cooking-tab-selected {
	background: linear-gradient(to bottom, rgb(44, 30, 0), rgb(80, 60, 0)) !important;
}

.cooking-tabs > div:first-child {
	border-radius: 20px 0 0 20px;
	box-shadow: inset -11px 0 8px -10px rgb(128, 80, 0);
	border-right: none;
}

.cooking-tabs > div:nth-child(2) {
	box-shadow: inset -11px 0 8px -10px rgb(128, 80, 0), inset 11px 0 8px -10px rgb(128, 80, 0);
	border-left: none;
	border-right: none;
}

.cooking-tabs > div:nth-child(3) {
	border-radius: 0 20px 20px 0;
	box-shadow: inset 11px 0 8px -10px rgb(128, 80, 0);
	border-left: none;
}

.cooking-tabs > div > img {
	filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.9));
}

.theme-cooking {
	background-image: url('/images/cooking/cooking_screen.png');
}
