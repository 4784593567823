.user-information {
	min-width: 200px;
	text-align: center;
	width: 100%;
	color: white;
}

.icon75 {
	height: 45px;
	width: 45px;
	margin-top: 5px;
	margin-bottom: 5px;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

#gold,
#heat,
#totalLevel,
#platinum {
	display: inline-block;
	vertical-align: middle !important;

	font-size: 30px;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

#platinum {
	color: rgb(0, 225, 255);
}

@keyframes gold-animation {
	46% {
		transform: unset;
	}
	46.5% {
		transform: rotate(-5deg);
	}
	47% {
		transform: rotate(5deg);
	}
	47.5% {
		transform: rotate(-5deg);
	}
	48% {
		transform: rotate(5deg);
	}
	48.5% {
		transform: rotate(-5deg);
	}
	49% {
		transform: rotate(5deg);
	}
	49.5% {
		transform: rotate(-5deg);
	}
	50% {
		transform: rotate(5deg);
	}
	50.5% {
		transform: rotate(-5deg);
	}
	51% {
		transform: unset;
	}
}

.gold-animation > img {
	animation: gold-animation 60s ease infinite;
}

.high-performance .gold-animation > img {
	animation: none;
}

@keyframes heat-animation {
	40% {
		filter: none;
	}
	50% {
		filter: drop-shadow(0 0 8px rgb(255, 70, 70)) drop-shadow(0 0 2px rgb(247, 125, 43));
	}
	60% {
		filter: none;
	}
}

.heat-animation > img {
	animation: heat-animation 60s ease infinite;
}

.high-performance .heat-animation > img {
	animation: none;
}
