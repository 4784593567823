.chat-skill-icon {
	width: 0.86em;
	height: 0.86em;
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.9));
	margin-top: -2px;
}

.skill-tooltip {
	pointer-events: none;
	z-index: 1000;
	font-size: small;
	width: 265px;
	height: 95px;
	overflow: auto;
	/* background: #191919;
    border: 2px solid white; */
	border-radius: 6px;
	padding: 5px;
	position: relative;
	opacity: 0.95;
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.skill-tooltip-image-container {
	float: left;
	margin-bottom: 8px;
	font-size: 0;
	display: flex;
	justify-content: center;
	height: auto;
	overflow: hidden;
}
.skill-tooltip-image {
	width: 70px;
	height: 70px;
}

.skill-tooltip-level-container {
	display: grid;
	grid-template-rows: 1fr 3px 1fr;
	justify-items: center;
}

.skill-tooltip-level {
	height: 39px;
	border-radius: 2px;
	font-size: 20px;
	line-height: 40px;
	float: right;
}

.skill-tooltip-level-mastery {
	color: cyan;
}

.skill-tooltip-level-exp {
	font-size: 20px;
	line-height: 20px;
	margin-top: 10px;
	float: right;
}
