.idlescape-container {
	border-image-source: url(/images/ui/stone-9slice-dark-gray.png);
	border-image-slice: 100 fill;
	border-image-width: 100px;
	border-image-outset: 0;
	padding: 30px;
	border-image-repeat: round;
}

.idlescape-container.thin {
	border-image-width: 50px;
	padding: 15px;
}

.mobile-layout .idlescape-container {
	border-image-width: 50px !important;
	padding: 15px !important;
}

img {
	/* pointer-events: none !important; */
}

input[type='text'] {
	user-select: text !important;
}

input::placeholder {
	color: white;
	opacity: 0.6;
}

.idlescape-container.idlescape-container-light {
	border-image-source: url(/images/ui/stone-9slice.png) !important;
}

.idlescape-container.idlescape-container-green {
	border-image-source: url(/images/ui/stone-9slice-green-v3.png) !important;
}

.idlescape-container.idlescape-container-blue {
	border-image-source: url(/images/ui/stone-9slice-blue-v3.png) !important;
}
.idlescape-container.idlescape-container-platinum {
	border-image-source: url(/images/ui/stone-9slice-platinum-v2.png) !important;
}

.idlescape-frame-box {
	background-image: url(/images/ui/frame_box.png);
	background-size: 100% 100%;
	background-position: center;
}

.idlescape-frame-box-dark {
	background-image: url(/images/ui/frame_box_dark.png);
	background-size: 100% 100%;
	background-position: center;
}

.idlescape-frame-box-interactive.selected {
	background-image: url(/images/ui/frame_box_light.png);
}

.idlescape-frame-box-interactive {
	transition: all 0.05s;
}

.idlescape-frame-box-interactive:hover {
	scale: 1.05;
	cursor: pointer;
}

.idlescape-frame-panel {
	background-image: url(/images/ui/frame_panel.png);
	background-size: 100% 100%;
	background-position: center;
}

.idlescape-frame-panel-dark {
	background-image: url(/images/ui/frame_panel_dark.png);
}

.idlescape-frame-panel-interactive.selected {
	background-image: url(/images/ui/frame_panel_light.png);
}

.idlescape-frame-panel[data-active='true'] {
	background-image: url(/images/ui/frame_panel_light.png);
}

.idlescape-frame-panel-interactive {
	transition: all 0.05s;
}

.idlescape-frame-panel-interactive:hover {
	scale: 1.05;
	cursor: pointer;
}

.idlescape-input {
	background-image: /* linear-gradient(to bottom, rgba(0, 0, 0, 0.582), rgba(0, 0, 0, 0.329)), */ url(/images/ui/frame_panel.png);
	background-size: 100% 100%;
	padding: 3px 7px;
	position: relative;
}

.idlescape-input > input {
	color: white;
	background-color: rgba(0, 0, 0, 0.5) !important;
	border-bottom: none !important;
	height: 100% !important;
	border-radius: 5px !important;
	margin-bottom: 0 !important;
	text-align: center;
	box-shadow: 0 0 10px 5px rgb(0, 0, 0, 0.5), 0 0 5px 5px rgb(0, 0, 0, 0.5) inset !important;
}

.melee-text,
.red-text {
	color: #f44336;
}

.range-text,
.green-text {
	color: #4caf50;
}

.magic-text {
	color: #2196f3;
}

.hybrid-text {
	color: #f92bec;
}
