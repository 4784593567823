.friendlist-container {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.friendlist-table th {
	font-weight: bold;
	font-size: 22px;
	color: white;
}

.header-fixed {
	width: 100%;
}

.friendlist-table tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.199);
}
