.scrollcrafting-main {
	display: grid;
	grid-template-columns: 1fr;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5px;
}

.scrollcrafting-list .scrollcrafting-button > button {
	background: #646464; /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom left, #589ac0, #3f7293); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to bottom left,
		#589ac0,
		#3f7293
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	border: 1px solid #589ac0;
	/* color: rgb(44, 44, 44); */
	color: white;
	font-size: 18px;
	text-shadow: 0 0 2px #0099ff;
	border-bottom-left-radius: 20px;
	margin-left: 2px;
	/* box-shadow: inset 0 0 10px 2px #a5a01e; */
}
.scrollcrafting-list .scrollcrafting-button > button:hover {
	background: #80bde6; /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom left, #70b4db, #569ac7) !important; /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to bottom left,
		#70b4db,
		#569ac7
	) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	border: 1px solid #80bde6;
	/* color: rgb(44, 44, 44); */
	color: white;
	font-size: 20px;
	text-shadow: 0 0 5px #80bde6 !important;
	/* box-shadow: inset 0 0 10px 2px #a5a01e; */
}

.scrollcrafting-info {
	grid-column-start: 1;
	grid-column-end: 3;
	padding: 10px;
	border: 1px solid rgba(238, 255, 0, 0.39);
	background-color: rgba(0, 0, 0, 0.658);
	margin: 10px auto;
	line-height: 20px;
	border-radius: 5px;
}

.scrollcrafting-filter-image {
	width: 35px;
	height: 35px;
	margin-left: 1px;
	margin-right: 1px;
	padding: 1px;
	object-fit: contain;
	cursor: pointer;
}

.scrollcrafting-filter-image:hover {
	transform: scale(1.1);
}

.scrollcrafting-filter-image.clicked {
	transform: scale(1.1);
	border: 2px solid gray;
}

.mobile-layout .scrollcrafting-main {
	width: 100%;
}

.mobile-layout .scrollcrafting-container {
	grid-template-columns: 40px 1fr 2fr 1fr;
	font-size: 12px;
}

.mobile-layout .scrollcrafting-title {
	text-shadow: -2px 0 #000, 0 2px #000, 2px 0 #000, 0 -2px #000;
	font-size: 16px;
	margin-left: -40px;
}

.mobile-layout .scrollcrafting-resources {
	padding-right: 0;
}

.mobile-layout .scrollcrafting-button > button {
	font-size: 16px;
}
