.loadout-container {
	height: 100%;
}

.loadout-slots {
	display: grid;
	grid-template-columns: repeat(auto-fit, 80px);
	grid-template-rows: repeat(auto-fit, 80px);
	row-gap: 5px;
	column-gap: 5px;
	justify-content: center;
}

.loadout-slot {
	border-radius: 10px;
	background-image: url(/images/ui/frame_box.png);
	background-size: cover;
	background-position: center;
	text-align: center;
	position: relative;
	height: 80px;
}

.loadout-slot-name {
	position: absolute;
	width: 100%;
	top: 0;
	margin-left: auto;
	margin-right: auto;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.loadout-slot > img {
	height: 80%;
	width: 65%;
	object-fit: contain;
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	margin-top: 10px;
}

.loadout-name {
	font-size: 28px;
	font-weight: bold;
	text-align: center;
}

.loadout-equipment-inventory-buttons {
	display: grid;
	grid-template-columns: repeat(auto-fit, 200px);
	column-gap: 20px;
	justify-content: center;
}

.loadout-action-buttons {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(max-content, 200px));
	gap: 5px;
	justify-content: center;
}

.loadout-equipment-container {
	height: 400px;
	position: relative;
	padding-top: 1px !important;
	width: 250px;
	margin: auto;
}

.loadout-equipment-container.loadout-hide-tools {
	height: 285px;
}

.loadout-inventory-container-wrapper,
.loadout-equipment-container-wrapper {
	width: 310px;
	margin-left: auto;
	margin-right: auto;
}

.loadout-equipment-container > img {
	height: 230px;
	min-width: 174px;
	display: block;
	margin: 36px auto 80px;
}

.loadout-inventory-container {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
	align-content: space-evenly;
	position: relative;
	overflow-y: auto;
	height: 400px;
	justify-content: center;
	width: 100%;
}

.combat-empty-slot {
	border: 1px solid rgba(0, 0, 0, 0);

	background-image: url(/images/ui/frame_box_dark.png) !important;
	background-size: cover !important;
	background-position: center !important;
}

.loadout-location-container {
	width: 100%;
	min-width: 200px;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
}
