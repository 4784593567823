.combat-dialogue-container {
	position: absolute;

	margin-left: auto;
	margin-right: auto;
	/* width: 50%; */
	/* height: 50%; */
	bottom: -50px;
	z-index: 200;
	text-align: center;
	width: 300px;
	pointer-events: none;
}

.combat-dialogue {
	border-image-source: url(/images/ui/stone-9slice.png) !important;
	border-image-slice: 100 fill !important;
	border-image-width: 100px !important;
	border-image-outset: 0 !important;
	/* padding: 50px !important; */
	border-image-repeat: round !important;
	width: 300px;
	margin-left: auto;
	margin-right: auto;
	/* height: 400px; */
	padding: 5px;
	/* padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px; */
}

.combat-dialogue-visible {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s linear;
}

.combat-dialogue-hidden {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 2s, opacity 2s linear;
}
