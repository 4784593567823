/* NEW STUFF */
.group-panel-container {
	overflow-y: auto;
	overflow-x: hidden;
}

.group-panel-member-username {
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	background-color: rgba(0, 0, 0, 0.582);
}

.group-panel-invited-player-notification {
	grid-column-start: 1;
	grid-column-end: 3;
	text-align: center;
	height: 20px;
	margin-bottom: 10px;
}

.group-panel-invited-player-notification span {
	font-weight: bold;
}

.group-panel-container .inventory-panel {
	max-height: none;
}
