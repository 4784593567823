:root {
	--shield: 1; /* shield-1 */
	--quiver: var(--shield) + 1;
	--cape-back: var(--quiver) + 1;
	--quiver-front: var(--cape-back) + 1;
	--body-back: var(--quiver-front) + 1;
	--helm-back: var(--body-back) + 1; /* helm-2 */
	--model: var(--helm-back) + 1;
	--base: var(--model) + 1;
	--eyes: var(--model) + 1;
	--mouth: var(--model) + 1;
	--beard: var(--mouth) + 1;
	--nose: var(--beard) + 1;
	--face: var(--nose) + 1;
	--boots: var(--model) + 1;
	--legs: var(--boots) + 1;
	--body: var(--legs) + 1;
	--helm-front: var(--body) + 1; /* helm-1 */
	--cape-front: var(--helm-front) + 1;
	--gloves-left-back: var(--cape-front) + 1; /* gloves-2 */
	--shield-handle: var(--gloves-left-back) + 1; /* shield-2 */
	--weapon-left-back: var(--shield-handle) + 1;
	--weapon-right-back: var(--weapon-left-back) + 1;
	--hands: var(--weapon-right-back) + 1;
	--gloves-left-front: var(--hands) + 1; /* gloves-3 */
	--gloves-right: var(--gloves-left-front) + 1; /* gloves-1 */
	--weapon-hands: var(--gloves-right) + 1;
	--weapon-left-front: var(--weapon-hands) + 1;
	--weapon-right-front: var(--weapon-left-front) + 1;
}

.combat-gear-item::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50%;
	left: 0;
}

#gear-helm {
	top: 6px;
	left: calc(50% - 25px);
	background-image: url('/images/combat/helmet_icon.png');
}

#gear-helm::before {
	background-image: url('/images/combat/helmet_icon.png');
}

#gear-cape {
	top: 61px;
	left: calc(50% - 90px);
}

#gear-cape::before {
	background-image: url('/images/combat/cape_icon.png');
}

#gear-arrows {
	top: 61px;
	left: calc(50% + 40px);
}

#gear-arrows::before {
	background-image: url('/images/combat/arrows_icon.png');
}

#gear-necklace {
	top: 61px;
	left: calc(50% - 25px);
	background-image: url('/images/combat/amulet_icon.png');
}

#gear-necklace::before {
	background-image: url('/images/combat/amulet_icon.png');
}

#gear-weapon {
	top: 116px;
	left: calc(50% - 110px);
	background-image: url('/images/combat/weapon_icon.png');
}

#gear-weapon::before {
	background-image: url('/images/combat/weapon_icon.png');
}

#gear-body {
	top: 116px;
	left: calc(50% - 25px);
	background-image: url('/images/combat/body_icon.png');
}

#gear-body::before {
	background-image: url('/images/combat/body_icon.png');
}

#gear-shield {
	top: 116px;
	left: calc(50% + 60px);
	background-image: url('/images/combat/shield_icon.png');
}

#gear-shield::before {
	background-image: url('/images/combat/shield_icon.png');
}

#gear-legs {
	top: 171px;
	left: calc(50% - 25px);
	background-image: url('/images/combat/legs_icon.png');
}

#gear-legs::before {
	background-image: url('/images/combat/legs_icon.png');
}

#gear-gloves {
	top: 226px;
	left: calc(50% - 110px);
	background-image: url('/images/combat/gloves_icon.png');
}

#gear-gloves::before {
	background-image: url('/images/combat/gloves_icon.png');
}

#gear-boots {
	top: 226px;
	left: calc(50% - 25px);
	background-image: url('/images/combat/boots_icon.png');
}

#gear-boots::before {
	background-image: url('/images/combat/boots_icon.png');
}

#gear-ring {
	top: 226px;
	left: calc(50% + 60px);
	background-image: url('/images/combat/ring_icon.png');
}

#gear-ring::before {
	background-image: url('/images/combat/ring_icon.png');
}

#gear-pickaxe {
	top: 288px;
	left: calc(50% - 80px);
	background-image: url('/images/combat/pickaxe_icon.png');
}

#gear-pickaxe::before {
	background-image: url('/images/combat/pickaxe_icon.png');
}

#gear-hatchet {
	top: 288px;
	left: calc(50% - 25px);
	background-image: url('/images/combat/hatchet_icon.png');
}

#gear-hatchet::before {
	background-image: url('/images/combat/hatchet_icon.png');
}

#gear-hoe {
	top: 288px;
	left: calc(50% + 30px);
	background-image: url('/images/combat/hoe_icon.png');
}

#gear-hoe::before {
	background-image: url('/images/combat/hoe_icon.png');
}

#gear-tacklebox {
	top: 342px;
	left: calc(50% - 80px);
	background-image: url('/images/combat/tacklebox_icon.png');
}

#gear-tacklebox::before {
	background-image: url('/images/combat/tacklebox_icon.png');
}

#gear-tome {
	top: 342px;
	left: calc(50% - 25px);
	background-image: url('/images/combat/tome_icon.png');
}

#gear-tome::before {
	background-image: url('/images/combat/tome_icon.png');
}

#gear-tongs {
	top: 342px;
	left: calc(50% + 30px);
	background-image: url('/images/combat/tongs_icon.png');
}

#gear-tongs::before {
	background-image: url('/images/combat/tongs_icon.png');
}

#gear-base {
	top: 6px;
	left: calc(50% + 40px);
	background-image: url('/images/combat/base_icon.png');
}

#gear-base::before {
	background-image: url('/images/combat/base_icon.png');
}

#gear-face {
	top: 6px;
	left: calc(50% - 90px);
	background-image: url('/images/combat/face_icon.png');
}

#gear-face::before {
	background-image: url('/images/combat/face_icon.png');
}

#gear-ladle {
	top: 315px;
	left: calc(50% + 82px);
	background-image: url('/images/combat/ladle_icon.png');
}

#gear-ladle::before {
	background-image: url('/images/combat/ladle_icon.png');
}

#gear-chisel {
	top: 315px;
	left: calc(50% - 133px);
	background-image: url('/images/combat/chisel_icon.png');
}

#gear-chisel::before {
	background-image: url('/images/combat/chisel_icon.png');
}

.visual {
	position: absolute;
}

.scimitar {
	width: 25% !important;
	top: -13%;
	left: 0;
	z-index: calc(var(--weapon-right-back));
}

.fishtail {
	width: 87.5% !important;
	top: -20.8%;
	left: -45%;
	z-index: calc(var(--weapon-right-back));
}

.zero {
	width: 70% !important;
	top: -20.8%;
	left: -17.5%;
	z-index: calc(var(--weapon-right-back));
}
.forgotten-soul-axe {
	width: 70% !important;
	top: -20.8%;
	left: -17.5%;
	z-index: calc(var(--weapon-right-front));
}
.forgotten-soul-scim {
	width: 55% !important;
	top: -33.8%;
	left: -15%;
	z-index: calc(var(--weapon-right-front));
}
.forgotten-soul-two {
	width: 65% !important;
	top: 5.2%;
	left: -20%;
	z-index: calc(var(--weapon-right-front));
}

.battleaxe {
	width: 30% !important;
	top: 10.4%;
	left: 5.5%;
	z-index: calc(var(--weapon-right-back));
}

.log-ladle {
	width: 48% !important;
	top: 7%;
	left: -11%;
	z-index: calc(var(--weapon-right-back));
}

.demon-spear {
	width: 33% !important;
	top: -8%;
	left: 1%;
	z-index: calc(var(--weapon-right-back));
}

.stygian-battleaxe {
	width: 40% !important;
	top: 10.4%;
	left: -2%;
	z-index: calc(var(--weapon-right-back));
}

.wrath-axe {
	width: 140% !important;
	top: -14.3%;
	left: -52.5%;
	z-index: calc(var(--weapon-right-back));
}
.dagger-1h {
	width: 35% !important;
	top: 22.1%;
	left: 0;
	z-index: calc(var(--weapon-right-back));
}
.defender {
	width: 35% !important;
	top: 5.2%;
	left: 77.5%;
	z-index: calc(var(--weapon-left-back));
}

.daggers-1 {
	width: 80% !important;
	top: -100%;
	left: -130%;
	z-index: calc(var(--weapon-right-back));
}

.daggers-2 {
	width: 80% !important;
	top: -40%;
	left: 200%;
	z-index: calc(var(--weapon-left-back));
}

.stygian-daggers-1 {
	width: 100% !important;
	top: -100%;
	left: -130%;
	z-index: calc(var(--weapon-right-back));
}

.stygian-daggers-2 {
	width: 100% !important;
	top: -60%;
	left: 180%;
	z-index: calc(var(--weapon-left-back));
}

.sword-2h {
	width: 40% !important;
	top: 23.4%;
	left: -3.5%;
	z-index: calc(var(--weapon-right-back));
}

.stygian-sword-2h {
	width: 40% !important;
	top: 22.3%;
	left: -3.5%;
	z-index: calc(var(--weapon-right-back));
}

.platebody {
	width: 100%;
	left: -2.5%;
	top: 9.6%;
	z-index: calc(var(--body));
}

.chainbody {
	left: 8%;
	top: 14%;
	width: 67% !important;
	z-index: calc(var(--body));
}

.smock {
	left: 2%;
	top: 10.9%;
	width: 75% !important;
	z-index: calc(var(--body));
}

.chef-body {
	left: 2.5%;
	top: 10.9%;
	width: 80% !important;
	z-index: calc(var(--body));
}

.rangebody {
	max-width: 69%;
	left: 7.5%;
	top: 11.9%;
	z-index: calc(var(--body));
}
.range-med-helm-1 {
	top: -220%;
	left: -50%;
	width: 180% !important;
	z-index: calc(var(--helm-front));
}

.range-med-helm-2 {
	top: -220%;
	left: -50%;
	width: 180% !important;
	z-index: calc(var(--helm-back));
}
.range-med-helm-alt-1 {
	top: -222%;
	left: -110%;
	width: 276% !important;
	z-index: calc(var(--helm-front));
}

.range-med-helm-alt-2 {
	top: -210%;
	left: -110%;
	width: 276% !important;
	z-index: calc(var(--helm-back));
}

.full-helm {
	left: 16%;
	top: -14.3%;
	width: 50% !important;
	z-index: calc(var(--helm-front));
}

.med-helm-1 {
	top: -226%;
	left: -30%;
	width: 160% !important;
	z-index: calc(var(--helm-front));
}

.med-helm-2 {
	top: -160%;
	left: 40%;
	width: 80% !important;
	z-index: calc(var(--helm-back));
}

.bucket-hat {
	left: 19.5%;
	top: -8.6%;
	width: 51.5% !important;
	z-index: calc(var(--helm-front));
}

.chef-hat {
	left: 20.5%;
	top: -6.5%;
	width: 42% !important;
	z-index: calc(var(--helm-front));
}

.lumberjack-hat {
	left: 32.5%;
	top: -3%;
	width: 31% !important;
	z-index: calc(var(--helm-front));
}

.platelegs {
	width: 80% !important;
	left: 5%;
	top: 41.6%;
	z-index: calc(var(--legs));
}

.waders {
	width: 82.5% !important;
	left: 2.5%;
	top: 13%;
	z-index: calc(var(--legs));
}

.lumberjack-legs {
	width: 75% !important;
	left: 5%;
	top: 16%;
	z-index: calc(var(--legs));
}

.chef-legs {
	width: 74% !important;
	left: 8.2%;
	top: 42%;
	z-index: calc(var(--legs));
}

.boots {
	left: -5%;
	bottom: -2.3%;
	width: 112% !important;
	z-index: calc(var(--boots));
}

.winged-boots {
	left: -12%;
	bottom: -6.2%;
	width: 119% !important;
	z-index: calc(var(--boots));
}

.gloves-1 {
	left: -127%;
	top: 53%;
	width: 104% !important;
	z-index: calc(var(--gloves-right));
}

.gloves-2 {
	right: -132%;
	top: 69.6%;
	z-index: calc(var(--gloves-left-back));
	width: 100.8%;
}

.gloves-3 {
	right: -165.5%;
	top: 77%;
	z-index: calc(var(--gloves-left-front));
	width: 100.8% !important;
}

.shield-1 {
	right: -290%;
	top: -160%;
	z-index: calc(var(--shield));
	width: 430% !important;
}

.shield-2 {
	right: -160%;
	top: 30%;
	z-index: calc(var(--shield-handle));
	width: 90% !important;
}

.door-1 {
	right: -300%;
	top: -180%;
	z-index: calc(var(--shield));
	width: 540% !important;
}

.door-2 {
	right: -160%;
	top: 16%;
	z-index: calc(var(--shield-handle));
	width: 90% !important;
}

.santa-hat {
	left: 16%;
	top: -7.8%;
	width: 50% !important;
	z-index: calc(var(--helm-front));
}

.snowman-head {
	left: 18.5%;
	top: -13.8%;
	width: 50% !important;
	z-index: calc(var(--helm-front));
}

.crest-of-chaos {
	left: -30.5%;
	top: -17.9%;
	width: 99% !important;
	z-index: calc(var(--helm-front));
	filter: drop-shadow(3px 1px 2px rgba(0, 0, 0, 0.9)) !important;
}

.tophat {
	left: 22.5%;
	top: -11.7%;
	width: 50% !important;
	z-index: calc(var(--helm-front));
}

.greater-ladle {
	width: 57.5% !important;
	top: 23.4%;
	left: -10%;
	z-index: calc(var(--weapon-right-back));
}

.lesser-ladle {
	width: 20% !important;
	top: 20.8%;
	left: 5%;
	z-index: calc(var(--weapon-right-back));
}

.ladle {
	width: 50% !important;
	top: 5.2%;
	left: -10%;
	z-index: calc(var(--weapon-right-back));
}

.moss-maul {
	width: 61% !important;
	left: -8.5%;
	top: 17.4%;
	z-index: calc(var(--weapon-right-back));
}

.eyes-of-chaos {
	width: 45% !important;
	left: -12%;
	top: 23%;
	z-index: calc(var(--weapon-right-back));
}

.ban-hamer {
	width: 61% !important;
	left: -15%;
	top: 5.2%;
	z-index: calc(var(--weapon-right-back));
}

#character-model,
#character-base {
	z-index: calc(var(--model));
	position: relative;
	width: 100%;
}

#character-eyes {
	height: 3.4%;
	width: 14%;
	position: absolute;
	left: 47.5%;
	top: 5.2%;
	z-index: calc(var(--eyes));
}

#character-nose {
	height: 4.7%;
	width: 7.5%;
	position: absolute;
	left: 51.5%;
	top: 6.2%;
	z-index: calc(var(--nose));
}

#character-mouth {
	height: 3.4%;
	width: 7.5%;
	position: absolute;
	left: 50.5%;
	z-index: calc(var(--mouth));
	top: 10.1%;
}

.character-beard {
	height: 14.3% !important;
	width: 21% !important;
	position: absolute;
	left: calc(43.5%);
	z-index: calc(var(--beard));
	top: 2.9%;
}

#character-hands {
	height: 18.4%;
	width: 92.9%;
	position: absolute;
	left: 4.2%;
	top: 31.3%;
	z-index: calc(var(--hands));
}

.modified-diving-gloves {
	height: 42.6%;
	width: 135% !important;
	position: absolute;
	left: -15%;
	top: 13%;
	z-index: calc(var(--weapon-hands));
}

.kings-crown {
	left: 22.5%;
	top: -17.7%;
	width: 45% !important;
	z-index: calc(var(--helm-front));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.5) !important;
}

.shard-of-kalanahmatti {
	width: 50% !important;
	top: -17.1%;
	left: -6.5%;
	z-index: calc(var(--weapon-right-back));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.3) !important;
}

.fire-orb {
	width: 55% !important;
	top: -3.9%;
	left: 60%;
	z-index: calc(var(--weapon-left-front));
}

.deathmetalwig-1 {
	top: -230%;
	left: -44%;
	width: 200% !important;
	z-index: calc(var(--helm-front));
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.9)) brightness(1.3) !important;
}

.deathmetalwig-2 {
	top: -170%;
	left: 68%;
	width: 50% !important;
	z-index: calc(var(--helm-back));
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.9)) brightness(1.3) !important;
}

.cornyjoke {
	top: -13%;
	left: 42.5%;
	width: 35% !important;
	z-index: calc(var(--helm-front));
}

.mysteriousman {
	top: 2.1%;
	left: 40%;
	width: 25% !important;
	z-index: calc(var(--face));
}

.chainbody-gargoyle {
	left: 8%;
	top: 13%;
	width: 75% !important;
	z-index: calc(var(--legs));
}

.gargoyle-shield-1 {
	right: -330%;
	top: -200%;
	width: 430% !important;
	z-index: calc(var(--shield));
}

.gargoyle-shield-2 {
	right: -170%;
	top: 20%;
	width: 90% !important;
	z-index: calc(var(--shield-handle));
}

.gargoyle-helm-1 {
	top: -226%;
	left: -70%;
	width: 240% !important;
	z-index: calc(var(--helm-front));
}

.gargoyle-helm-2 {
	top: -160%;
	left: 20%;
	width: 80% !important;
	z-index: calc(var(--helm-back));
}

.gargoyle-falchion {
	width: 40% !important;
	top: -7.8%;
	left: -5%;
	z-index: calc(var(--weapon-right-back));
}

.gargoyle-daggers-1 {
	width: 80% !important;
	top: -120%;
	left: -120%;
	z-index: calc(var(--weapon-right-back));
}

.gargoyle-daggers-2 {
	width: 120% !important;
	top: -40%;
	left: 180%;
	z-index: calc(var(--weapon-left-back));
}

.spear {
	width: 30% !important;
	max-height: none !important;
	left: 0;
	top: -20.8%;
	z-index: calc(var(--weapon-right-back));
}

.scythe {
	width: 125% !important;
	max-height: none !important;
	left: -9.5%;
	top: -16.6%;
	z-index: calc(var(--weapon-right-back));
}

.black-knight-full-helm {
	top: -14.3%;
	left: -15%;
	width: 85% !important;
	z-index: calc(var(--helm-front));
}

.shrimp-armor {
	left: -32.5%;
	top: -7.8%;
	width: 140% !important;
	z-index: calc(var(--body));
}

.shrimp-shield-1 {
	right: -320%;
	top: -300%;
	width: 600% !important;
	z-index: calc(var(--shield));
}

.shrimp-shield-2 {
	right: -170%;
	top: 55%;
	width: 90% !important;
	z-index: calc(var(--shield-handle));
}

.shrimp-helm {
	top: -15.6%;
	left: 0;
	width: 80% !important;
	z-index: calc(var(--helm-front));
}

.shrimp-legs {
	width: 115% !important;
	left: -16%;
	top: 39%;
	z-index: calc(var(--legs));
}

/* CHRISTMAS */
.christmas-helmet {
	left: 17.5%;
	top: -6.2%;
	width: 55% !important;
	z-index: calc(var(--helm-front));
}

.christmas-breastplate {
	left: -18.5%;
	top: -6.8%;
	width: 110% !important;
	z-index: calc(var(--body));
}

.christmas-leggings {
	left: 5%;
	top: 41.6%;
	width: 80% !important;
	z-index: calc(var(--legs));
}

.christmas-shield {
	left: 21.5%;
	top: 14%;
	width: 100% !important;
	z-index: calc(var(--shield));
}

.christmas-weapon {
	left: 1%;
	top: 19%;
	width: 65% !important;
	z-index: calc(var(--weapon-right-back));
}

.magical-snowball {
	width: 35% !important;
	top: 20%;
	left: 71.5%;
	z-index: calc(var(--weapon-left-front));
}

.candy-cane-daggers-1 {
	width: 100% !important;
	top: -80%;
	left: -126%;
	z-index: calc(var(--weapon-right-back));
}

.candy-cane-daggers-2 {
	width: 100% !important;
	top: -30%;
	left: 180%;
	z-index: calc(var(--weapon-left-back));
}

.christmas-gloves-1 {
	left: -124%;
	top: 54%;
	width: 100% !important;
	height: 100%;
	z-index: calc(var(--gloves-right));
}
.christmas-gloves-2 {
	right: -128%;
	top: 82%;
	z-index: calc(var(--gloves-left-front));
	width: 100%;
} /* Todo: Fix gloves */
.christmas-gloves-3 {
	right: -175%;
	top: 82%;
	z-index: calc(var(--gloves-left-front));
	width: 100% !important;
}

.cape {
	width: 95%;
	left: -10%;
	top: 4.4%;
	z-index: calc(var(--cape-back));
}

.chorus-of-souls {
	width: 45% !important;
	left: -9%;
	top: 22.1%;
	z-index: calc(var(--weapon-right-back));
}

.crystal-mace {
	width: 55% !important;
	left: -16.5%;
	top: -8.6%;
	z-index: calc(var(--weapon-right-back));
}

.castled-king {
	transform: scaleX(-1);
	width: 180% !important;
	left: -45%;
	max-height: 180% !important;
	z-index: calc(var(--base));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.25) !important;
}

.zoomba {
	width: 85% !important;
	left: -5%;
	max-height: 85% !important;
	z-index: calc(var(--base));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.25) !important;
}

.combat-avatar-mugshot-container .castled-king {
	width: 200% !important;
	left: -70%;
	top: -22%;
}

.p-chicken {
	transform: scaleX(-1);
	width: 80% !important;
	max-height: 100% !important;
	z-index: calc(var(--base));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.25) !important;
}

.p-chicken-2 {
	transform: scaleX(-1);
	width: 100% !important;
	max-height: 100% !important;
	z-index: calc(var(--base));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.25) !important;
}

.combat-avatar-mugshot-container .p-chicken {
	left: -55%;
	width: 120% !important;
}

.combat-avatar-mugshot-container .p-chicken-2 {
	left: -75%;
	top: -40%;
	width: 150% !important;
}

.hamster,
.hamster-2,
.hamster-3 {
	transform: scaleX(-1);
	width: 80% !important;
	max-height: 100% !important;
	z-index: calc(var(--base));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.25) !important;
}

.combat-avatar-mugshot-container .hamster {
	left: -4%;
	top: -10%;
	width: 70% !important;
}
.combat-avatar-mugshot-container .hamster-2 {
	left: -4%;
	top: -5.5%;
	width: 70% !important;
}
.combat-avatar-mugshot-container .hamster-3 {
	left: -4%;
	top: -4.5%;
	width: 70% !important;
}

.phoenix-cape {
	width: 135%;
	left: -25%;
	top: 2.6%;
	z-index: calc(var(--cape-back));
}

.shrimp-whip {
	width: 85% !important;
	left: 1.5%;
	top: 14.3%;
	z-index: calc(var(--weapon-right-back));
}

.shrimp-dagger {
	width: 35% !important;
	left: 1.5%;
	top: 1.3%;
	z-index: calc(var(--weapon-right-back));
}

.group-avatars {
	transform: scale(30%);
}

.awakened-titanic-gauntlet-gloves-2 {
	right: -126%;
	top: 66%;
	z-index: calc(var(--gloves-left-back));
	width: 102.4%;
}

.awakened-titanic-gauntlet-gloves-3 {
	right: -144%;
	top: 93%;
	z-index: calc(var(--gloves-left-front));
	width: 59.4% !important;
}

.longbow {
	width: 40% !important;
	left: 60%;
	top: -6.2%;
	transform: rotate(18deg);
	z-index: calc(var(--weapon-right-back));
}

.recurvebow {
	width: 60% !important;
	left: 51%;
	top: 6.2%;
	transform: rotate(26deg);
	z-index: calc(var(--weapon-right-back));
}

.crossbow {
	width: 110% !important;
	top: -10.4%;
	left: 58.5%;
	z-index: calc(var(--weapon-left-back));
}

.throwingdagger {
	width: 27.5% !important;
	top: 7.8%;
	left: 2.5%;
	z-index: calc(var(--weapon-right-back));
}

.throwingaxe {
	width: 35% !important;
	top: 10.4%;
	left: 4.5%;
	z-index: calc(var(--weapon-right-back));
}

.throwingjavelin {
	transform: rotate(180deg);
	width: 52.5% !important;
	top: 0;
	left: -10%;
	z-index: calc(var(--weapon-right-back));
}

.throwingspinner {
	width: 85% !important;
	top: 16.6%;
	left: -22.5%;
	z-index: calc(var(--weapon-right-back));
}

.hood-1 {
	top: -220%;
	left: -130%;
	width: 300% !important;
	z-index: calc(var(--helm-front));
}

.hood-2 {
	top: -220%;
	left: -36%;
	width: 180% !important;
	z-index: calc(var(--helm-back));
}

.robetop {
	width: 87.5% !important;
	left: -0.5%;
	top: 13%;
	z-index: calc(var(--body));
}

.robebottom {
	width: 85% !important;
	left: 2.5%;
	top: 41.6%;
	z-index: calc(var(--legs));
}

.staff {
	width: 33% !important;
	max-height: none !important;
	left: -0.5%;
	top: 3.4%;
	z-index: calc(var(--weapon-right-back));
}

.sylvan-staff {
	width: 67.5% !important;
	max-height: none !important;
	left: -16.5%;
	top: -26%;
	z-index: calc(var(--weapon-right-back));
}

.shapeless-staff {
	width: 41% !important;
	max-height: none !important;
	left: -2.5%;
	top: -20.8%;
	z-index: calc(var(--weapon-right-back));
}

.shapeless-longbow {
	width: 120% !important;
	left: 2%;
	top: -15.6%;
	z-index: calc(var(--weapon-right-back));
}

.wand {
	width: 27.5% !important;
	max-height: none !important;
	left: 4%;
	top: 6.8%;
	z-index: calc(var(--weapon-right-back));
}

.focus {
	width: 50% !important;
	max-height: none !important;
	left: 69%;
	top: 24.2%;
	z-index: calc(var(--weapon-left-back));
}

.focus-2 {
	width: 27.5% !important;
	max-height: none !important;
	left: 77.5%;
	top: 28.6%;
	z-index: calc(var(--weapon-left-back));
}

.focus-3 {
	width: 32.5% !important;
	max-height: none !important;
	left: 80%;
	top: 24.9%;
	z-index: calc(var(--weapon-left-back));
}

.focus-4 {
	width: 26.5% !important;
	max-height: none !important;
	left: 69%;
	top: 35.8%;
	z-index: calc(var(--weapon-left-back));
}

.focus-5 {
	width: 50% !important;
	max-height: none !important;
	left: 58%;
	top: 23%;
	z-index: calc(var(--weapon-left-front));
	transform: scaleX(-1);
}

.avenger-helm {
	left: 16%;
	top: -19%;
	width: 62.5% !important;
	z-index: calc(var(--helm-front));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.5) !important;
}

.avenger-breastplate {
	left: 6%;
	top: 12.5%;
	width: 77.5% !important;
	z-index: calc(var(--body));
}
.avenger-gloves-1 {
	left: -109%;
	top: 68%;
	width: 63.5% !important;
	z-index: calc(var(--gloves-right));
}

.avenger-gloves-2 {
	right: -140%;
	top: 96%;
	z-index: calc(var(--gloves-left-back));
	width: 69.8% !important;
}

.avenger-gloves-3 {
	right: -140%;
	top: 96%;
	z-index: calc(var(--gloves-left-front));
	width: 45% !important;
}

.avenger-cape {
	width: 90% !important;
	left: 0;
	top: 15.6%;
	z-index: calc(var(--cape-back));
}

.avenger-leggings {
	width: 73% !important;
	left: 6.5%;
	top: 40.3%;
	z-index: calc(var(--legs));
}

.avenger-boots {
	left: -1.5%;
	bottom: -0.8%;
	width: 105% !important;
	z-index: calc(var(--boots));
}

.madfaith {
	left: -65%;
	top: -13%;
	width: 250% !important;
	max-height: 250% !important;
	z-index: calc(var(--base));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.25) !important;
}

.combat-avatar-mugshot-container .madfaith {
	left: -90%;
}

.ghost-body-front {
	left: -124%;
	top: -131.7%;
	width: 320% !important;
	z-index: calc(var(--body));
}

.ghost-body-back {
	left: -38%;
	top: -174%;
	width: 150% !important;
	z-index: calc(var(--body-back));
}

.ghost-legs {
	width: 140% !important;
	left: -26%;
	top: 25%;
	z-index: calc(var(--legs));
}

.camo-back {
	width: 380% !important;
	left: -160%;
	top: -40%;
	z-index: calc(var(--cape-back));
}
.camo-front {
	width: 380% !important;
	left: -170%;
	top: -150%;
	z-index: calc(var(--cape-front));
}

.night-wanderer {
	left: 7.2%;
	top: -25.3%;
	width: 85% !important;
	z-index: calc(var(--helm-front));
}

.celestial {
	width: 155% !important;
	z-index: calc(var(--base));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9)) brightness(1.25) !important;
	left: -30%;
	position: relative;
}

.quiver-1 {
	width: 70% !important;
	left: 0;
	top: 30%;
	z-index: calc(var(--quiver-front));
	transform: rotate(-110deg);
}

.quiver-2 {
	width: 70% !important;
	left: 25%;
	top: 5%;
	z-index: calc(var(--quiver));
}

.tiny-wise-hat-2 {
	top: -260%;
	left: -25%;
	width: 100% !important;
	rotate: -5deg;
	z-index: calc(var(--helm-front));
}

.tiny-wise-hat-1 {
	top: -260%;
	left: -25%;
	width: 100% !important;
	rotate: -5deg;
	z-index: calc(var(--helm-back));
}

.medium-wise-hat-2 {
	top: -330%;
	left: -80%;
	width: 200% !important;
	rotate: -5deg;
	z-index: calc(var(--helm-front));
}

.medium-wise-hat-1 {
	top: -330%;
	left: -80%;
	width: 200% !important;
	rotate: -5deg;
	z-index: calc(var(--helm-back));
}

.big-wise-hat-2 {
	top: -365%;
	left: -130%;
	width: 275% !important;
	rotate: -10deg;
	z-index: calc(var(--helm-front));
}

.big-wise-hat-1 {
	top: -365%;
	left: -130%;
	width: 275% !important;
	rotate: -10deg;
	z-index: calc(var(--helm-back));
}

.necro-cowl {
	top: -15%;
	left: 28%;
	width: 39.5% !important;
	z-index: calc(var(--helm-front));
}

.necro-top {
	width: 88% !important;
	left: -1%;
	top: 13.5%;
	z-index: calc(var(--body));
}

.necro-legs {
	width: 88% !important;
	left: -3.5%;
	top: 41.5%;
	z-index: calc(var(--legs));
}

.necro-focus {
	width: 50% !important;
	max-height: none !important;
	left: 51%;
	top: 33.5%;
	z-index: calc(var(--weapon-left-back));
}

.necro-wand {
	width: 33.5% !important;
	max-height: none !important;
	left: -2%;
	top: 11%;
	z-index: calc(var(--weapon-right-back));
}

.necro-boots {
	left: -1.5%;
	bottom: -0.8%;
	width: 105% !important;
	z-index: calc(var(--boots));
}

.necro-gloves-1 {
	left: -109%;
	top: 68%;
	width: 63.5% !important;
	z-index: calc(var(--gloves-right));
}

.necro-gloves-2 {
	right: -140%;
	top: 96%;
	z-index: calc(var(--gloves-left-back));
	width: 69.8% !important;
}

.necro-gloves-3 {
	right: -140%;
	top: 96%;
	z-index: calc(var(--gloves-left-front));
	width: 45% !important;
}

.mantis-gloves-1 {
	left: -203%;
	top: 2%;
	width: 200% !important;
	z-index: calc(var(--gloves-right));
}

.mantis-gloves-2 {
	right: -200%;
	top: -18%;
	z-index: calc(var(--gloves-left-back));
	width: 210% !important;
}

.mantis-gloves-3 {
	right: -167%;
	top: 77%;
	z-index: calc(var(--gloves-left-front));
	width: 108% !important;
}

.elven-helm {
	top: -9.5%;
	left: 29.5%;
	width: 35% !important;
	z-index: calc(var(--helm-front));
}

.elven-legs {
	width: 74.5% !important;
	left: 7%;
	top: 40.5%;
	z-index: calc(var(--legs));
}
.elven-chest {
	left: -1%;
	top: 11.7%;
	width: 79% !important;
	z-index: calc(var(--body));
}

.handgonne {
	width: 60% !important;
	top: 13.4%;
	left: -2.5%;
	z-index: calc(var(--weapon-right-back));
	/* flip it */
	transform: scaleX(-1);
}

.cannon {
	width: 130% !important;
	top: 44%;
	left: -33%;
	z-index: calc(var(--weapon-right-front));
}

.marshall-gun {
	width: 30% !important;
	top: 24.5%;
	left: 8.3%;
	z-index: calc(var(--weapon-right-back));
}

.randomisator-horse {
	width: 100% !important;
	left: 0%;
	top: 0%;
	z-index: calc(var(--legs));
}

.trident {
	width: 81% !important;
	top: -20%;
	left: -25%;
	z-index: calc(var(--weapon-right-back));
}
