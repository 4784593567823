.levelbar-small {
	position: relative;
	width: 100%;
	height: 55px;
	background-color: rgba(0, 0, 0, 0.3);
	border-top: none;
	border-radius: 0 0 30px 30px;

	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
		url(/images/ui/stone_ui_tile_dark_gray.png);
	filter: drop-shadow(0 0 0.45rem black);
	box-shadow: 0 -10px 10px -5px rgb(0 0 0) inset;
	padding: 0 10px;
}

.small-skill-levels-total {
	background-color: rgb(36, 36, 36);
	flex: 1;
	flex-basis: 200px;
	height: 61px;
	position: relative;
	margin-top: -20px;
	padding-top: 20px;
	border-top: 0;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	justify-items: center;

	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.05)),
		url(/images/ui/stone_ui_tile_dark_gray.png);
	filter: drop-shadow(0 0 0.45rem black);
	box-shadow: 0 0 4px 4px rgb(0 0 0 / 30%) inset;
}

.icon25 {
	height: 25px;
	width: 25px;
	margin-top: 2px;
	margin-bottom: 2px;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
	margin-right: 10px;
}

.small-total-level,
.small-mastery-level {
	padding: 0 0 0 10px;
	display: flex;
	text-align: center;
	flex-grow: 0.5;
	flex-basis: 100px;
	font-size: 20px;
}

.small-total-level {
	padding: 0 10px 0 0;
	justify-content: end;
}

.small-status-action {
	position: relative;
	z-index: 1;
	white-space: nowrap;
	margin-top: -5px;
}

.small-status {
	display: flex;
}
