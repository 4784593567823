.smithing-container {
	display: grid;
	column-gap: 10px;
	row-gap: 5px;
	justify-content: center;
	grid-template-columns: minmax(auto, 800px);
}

.smithing-furnace-list {
	column-gap: 5px;
	display: grid;
	/* grid-template-columns: 150px 150px 150px; */
	grid-template-columns: repeat(3, minmax(50px, 1fr));
	/* grid-template-rows: minmax(50px, 150px); */
	/* grid-template-rows: minmax(150px, auto); */
	justify-content: center;
	text-align: center;
}

.smithing-furnace-list-item {
	padding: 10px;
	transition: transform 0.3s;
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.smithing-furnace-list-item:hover {
	cursor: pointer;
}

.smithing-furnace-list-item-active {
	transform: scale(1.2);
	filter: drop-shadow(0 0 5px #5ebeff) drop-shadow(0 0 5px rgba(94, 191, 255, 1));
}

.smithing-furnace-list-item-active:hover {
	cursor: default;
}

.smithing-furnace-list-item > img {
	pointer-events: none;
	display: block;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
}

.smithing-bar-list {
	padding: 10px 20px !important;
	display: grid;
	column-gap: 10px;
	row-gap: 10px;
	grid-template-columns: repeat(auto-fit, 60px);
	grid-template-rows: repeat(auto-fit, 60px);
	justify-content: space-around;

	width: 100%;
}

.smithing-bar-list-item {
	transition: transform 0.3s;
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	display: grid;
	align-items: center;
}

.smithing-bar-list-item:hover {
	cursor: pointer;
}

.smithing-bar-list-item-active {
	transform: scale(1.2);
	filter: drop-shadow(0 0 5px #5ebeff) drop-shadow(0 0 5px rgba(94, 191, 255, 1));
}

.smithing-bar-list-item-active:hover {
	cursor: default;
}

.smithing-bar-list-item > img {
	display: inline-block;
	max-width: 100%;
	vertical-align: middle;
}

.smithing-information {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	column-gap: 10px;
	row-gap: 10px;
	position: relative;
}

.smithing-information-general-section-title {
	font-size: 1.5rem;
	font-weight: bold;
	color: #5ebeff;
	text-align: center;
}

.smithing-information-general-section-content {
	text-align: center;
}

.smithing-information > div {
	background-color: rgba(0, 0, 0, 0.3);
	padding: 5px;
}

.smithing-information .smithing-furnace-locked-cover {
	background-color: rgba(0, 0, 0, 0.8);
}

.smithing-intensity img {
	pointer-events: none;
}

.smithing-intensity-slider {
	width: calc(100% - 20px);
	/* padding-left: 4%;
	padding-right: 4%;
	margin-left: 1%;
	margin-right: 3.25%; */
}

.smithing-intensity-slider-thumb {
	/* background-color: green; */
	height: 50px;
	text-align: center;
	line-height: 50px;
	width: 20px;
	top: -5px;
	cursor: pointer;

	background-image: /* linear-gradient(to bottom, rgba(255, 65, 65, 0.37), rgba(0, 0, 0, 0.37)),  */ url(/images/ui/frame_box.png);
	background-size: 100% 100%;
	background-position: center;
}

.smithing-information-input {
	height: 40px;
	display: grid;
	grid-template-columns: 1fr 1fr minmax(70px, max-content);
	/* column-gap: 2px; */
	justify-content: space-around;
	justify-items: center;
	position: relative;
	vertical-align: middle;
}

.smithing-information-input > img {
	justify-self: left;
	width: 40px;
	display: block;
	margin-top: auto;
	margin-bottom: auto;
	/* height: 40px; */
}

.smithing-information-input-amount {
	font-size: 1.25rem;
	justify-self: left;
	margin: auto 0;
	text-align: center;
}

.smithing-information-input-owned {
	font-size: 0.9rem;
	justify-self: right;
	display: inline-block;
	vertical-align: middle;
	margin: auto 0;
	text-align: right;
}

.smithing-information-input-owned img.icon16 {
	line-height: 40px;
	justify-self: right;
	width: 16px;
	height: 16px;
	display: inline-block;
	vertical-align: middle;
	/* margin-left: 3px; */
}

.smithing-totals {
	color: grey;
	font-size: 0.8em;
	font-weight: normal;
}
.smithing-information-input-owned .smithing-totals {
	font-size: 1em;
}

.theme-smithing {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
		url('/images/smithing/city_forge_with_background.png');
}

.smithing-background-1 {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
		url('/images/smithing/city_forge_with_background.png');
}

.smithing-background-2 {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
		url('/images/smithing/dwarven_forge_with_background.png');
}

.smithing-background-3 {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
		url('/images/smithing/volcanic_forge_with_background.png');
}

.theme-smithing {
	-webkit-transition: background-image 0.5s;
	transition: background-image 0.5s;
}

.smithing-intensity-slider-thumb-0 {
	display: none;
}

.smithing-furnace-locked > img {
	filter: grayscale(100%) brightness(0.5);
}

.smithing-furnace-locked-cover {
	cursor: default;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 26px;
}

.smithing-furnace-locked-cover-level {
	display: inline-block;
	position: absolute;
	border-radius: 20px;
	padding: 0 3px;
	margin-top: 10px;
	text-shadow: -2px 0 2px black, 0 2px 2px black, 2px 0 2px black, 0 -2px 2px black;
}

.smithing-list-and-progress-bar-container {
	min-height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fade-enter > div {
	opacity: 0;
}
.fade-enter-active > div {
	opacity: 1;
}
.fade-exit > div {
	opacity: 1;
}
.fade-exit-active > div {
	opacity: 0;
}
.fade-enter-active > div,
.fade-exit-active > div {
	transition: opacity 100ms;
}

.smithing-background-3 .idlescape-container {
	border-image-source: url(/images/ui/stone-9slice-red.png) !important;
}
.smithing-buttons {
	margin: 0 auto;
	width: 50%;
}
.smithing-buttons button {
	width: 100%;
}
.action-queue .smithing-buttons button {
	width: 50%;
}
