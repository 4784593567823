.marketplace-container {
	position: relative;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.marketplace-content {
	overflow-y: auto;
	height: 100%;
}

.marketplace-info {
	padding: 10px;
	border: 1px solid rgba(238, 255, 0, 0.39);
	background-color: rgba(0, 0, 0, 0.658);
	margin: 10px auto;
	line-height: 20px;
	border-radius: 5px;
}

.marketplace-navigation .marketplace-info {
	margin: 0;
	padding: 8px;
	white-space: nowrap;
	overflow: hidden;
}

.mobile-layout .marketplace-navigation .marketplace-info {
	display: none;
}

.mobile-layout .marketplace-navigation {
	grid-template-columns: repeat(auto-fit, 80px);
}

.mobile-layout .marketplace-navigation img {
	display: none;
}

.game-shop-item {
	height: 150px;
	width: 150px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-image: url(/images/ui/frame_box.png);
	background-size: cover;
	background-position: center;
	float: left;
	margin: 4px;
	cursor: pointer;
	position: relative;
}

.game-shop-item:hover {
	transform: scale(1.03);
	box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7), inset 0 0 20px 0 rgba(255, 255, 255, 0.7);
	border-radius: 10px;
}

.marketplace-game-shop {
	/*     padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px; */
	min-height: 170px;
}

.game-shop-image {
	height: 70%;
	width: 70%;
	object-fit: contain;
	margin-top: 3px;
}

.game-shop-item-price {
	position: relative;
	bottom: 0;
	width: 100%;
	text-align: center;
}

#lowest-price,
#lowest-price-npc {
	font-size: 11px;
}

.marketplace-sell-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	gap: 5px;
}
.mobile-layout .marketplace-sell-container {
	grid-template-columns: auto;
	grid-auto-rows: max-content;
}

.mobile-layout .marketplace-content .all-items {
	grid-row: 2;
}

.marketplace-no-items {
	width: 70%;
	background-color: black;
	margin-left: auto;
	margin-right: auto;
	font-size: 24px;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.849);
	border: 2px solid rgba(192, 192, 192, 0.2);
	text-align: center;
}

.marketplace-table .marketplace-table-row:nth-child(even) {
	background-color: rgba(78, 78, 78, 0.8);
}

.marketplace-table .marketplace-table-row:nth-child(odd) {
	background-color: rgba(53, 53, 53, 0.75);
}

.game-item-tooltip {
	max-width: 225px;
	overflow: visible !important;
}

.marketplace-history-container {
	position: relative;
	max-width: 100%;
	overflow: hidden;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.marketplace-history {
	position: relative;
	flex: 1;
	max-width: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.marketplace-history-pages {
	padding-top: 4px;
	flex: 0 1 30px;
	width: 100%;
	max-width: 100%;
	display: flex;
	column-gap: 5px;
	justify-content: center;
	text-align: center;
}

.marketplace-history-page {
	background: rgb(59, 59, 59);
	border-radius: 4px;
	min-width: 20px;
	padding-top: 3px;
}

.marketplace-history-page.selected {
	background: rgb(88, 88, 88);
}

.marketplace-history-page:hover {
	background: rgb(121, 121, 121);
}

.marketplace-history-header {
	background: rgb(73, 73, 73);
	line-height: 22px;
	color: rgb(255, 255, 255);
	border-radius: 6px 6px 0 0;
	text-align: center;
}

.marketplace-history-item > div {
	padding-top: 5px;
	padding-bottom: 5px;
}

.marketplace-history-item > :not(:last-child) {
	border-right: 2px rgb(44, 44, 44) solid;
}

.marketplace-history-item-date-i ~ .marketplace-history-item-date-i {
	padding-left: 5px;
}

.marketplace-history-item-price.sale {
	color: rgb(78, 255, 78);
}

.marketplace-history-item-price.purchase {
	color: rgb(250, 77, 77);
}

.event-shop-items {
	position: relative;
}

.number-overlay-event-shop {
	position: absolute;
	top: 50%;
	left: 43%;
	text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
	font-size: 36px;
}

.share-marketplace-listing-button {
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	cursor: pointer;
	vertical-align: middle;
	width: 40px;
	height: 40px;
}

.share-marketplace-listing-button:hover {
	filter: brightness(2);
}

.marketplace-own-listing {
	box-shadow: 7px 0 0 green inset;
}

.marketplace-show-both .market-tables-header {
	justify-content: space-between;
}

.marketplace-show-buy .market-sell,
.marketplace-show-sell .market-buy {
	display: none;
}

.marketplace-show-both .market-header-sell,
.marketplace-show-both .market-header-buy {
	margin-left: auto;
	margin-right: auto;
}

.marketplace-show-buy .market-header-sell,
.marketplace-show-buy .market-new-offer,
.marketplace-show-sell .market-header-buy,
.marketplace-show-sell .market-new-listing {
	display: none;
}

.marketplace-show-both .market-field-extra {
	display: none;
}

.mobile-layout .marketplace-show-both .market-new-offer,
.mobile-layout .marketplace-show-both .market-new-listing {
	display: none;
}
