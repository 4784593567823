.__react_component_tooltip {
	z-index: 1000;
}

.item-tooltip .item-rarity {
	-webkit-text-fill-color: initial;
}

.lightgreen-text {
	color: #48ec61 !important;
}

.lightred-text {
	color: rgb(255, 70, 70) !important;
}

.lightorange-text {
	color: rgb(247, 125, 43);
}

.lightyellow-text {
	color: rgb(255, 214, 50);
}

.lightblue-text {
	color: #5ebeff !important;
}

.react-tiny-popover-container {
	z-index: 1000000;
}

.rarity-junk {
	color: #9d9d9d;
}

.rarity-common {
	color: white;
}

.rarity-uncommon {
	color: #1eff00;
}

.rarity-rare {
	color: #0070dd;
}

.rarity-epic {
	color: #a335ee;
}

.rarity-legendary {
	color: #ff8000;
}
