.chat-item {
	position: relative;
	display: inline-block;
	border-radius: 6px;
	height: 100%;
	width: auto;
	font-size: 1em;
	margin-left: 4px;
	margin-right: 4px;
	padding-left: 6px;
	padding-right: 6px;
	color: white;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	line-height: 1.11em;
	background-size: 120% 120%;
	background-position: center;

	user-select: text !important;
}

.chat-chest-combined {
	filter: grayscale(0.5);
}
