.combat-ability-tooltip {
	min-width: 300px;
	max-width: 300px;

	border-image-source: url(/images/ui/stone-9slice.png) !important;
}

.combat-ability-tooltip-header {
	padding: 3px;
}

.combat-ability-tooltip-stat {
	font-size: 12px;
	font-weight: 300;
}

.combat-ability-tooltip-description {
	font-size: 14px;
	font-weight: 500;
}

.combat-ability-tooltip-stat-small {
	font-size: 10px;
	font-style: italic;
	color: lightgrey;
}
